import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../const';
import { toast } from 'react-toastify';
import { Oval } from "react-loader-spinner";
import { FaSortNumericUp, FaSortNumericDownAlt } from "react-icons/fa";
import { AiOutlineDelete, AiOutlinePrinter, AiOutlinePlusCircle } from 'react-icons/ai';

const Invoice = () => {

    const navigate = useNavigate();
    const [items, setItems] = useState([{
        client_id: '',
        lookup_id: '',
        year: '',
        amount: '',
    }]);
    const [clientList, setClientList] = useState([]);
    const [allClientList, setAllClientList] = useState([]);
    const [isFilter, setFilter] = useState(false);
    const [invoiceList, setInvoiceList] = useState([]);
    const [copyInvoiceList, setCopyInvoiceList] = useState([]);
    const [isAdd, setAdd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [groupList, setGroupList] = useState([]);
    const [isError, setError] = useState(false);
    const [selectedOption, setSelectedOption] = useState('')
    const [formData, setFormData] = useState({
        client_id: '',
        group_id: '',
        invoice_number: "",
        credit_days: "",
        invoice_date: "",
        invoice_for: "",
        total_amount: 0,
    })
    const [sortByDir, setSortByDir] = useState("");
    const [selectedColumns, setSelectedColumns] = useState([
        { label: "Invoice No", value: "invoice_number", search: "" },
        { label: "Invoice Date", value: "invoice_date", search: "" },
        { label: "Client Name", value: "client_name", search: "" },
    ]);

    const getClient = () => {
        axios.post(API_URL.PENDING_INVOICE, {}).then(res => {
            res.data.data.forEach((raw) => {
                raw.label = raw.firm_name;
                raw.value = raw.client_id;
            });
            setClientList(res.data.data)
        });
    }
    const getGroupClient = () => {
        axios.post(API_URL.GROUPS_LIST, {}).then(res => {
            let ct = res.data.data.map((raw) => {
                return {
                    label: raw.name,
                    value: raw.id
                }
            });
            setGroupList(ct)
        });
    }
    const addItem = () => {
        let copyItems = [...items];
        copyItems.push({
            client_id: null,
            service_id: null,
            lookup_id: null,
            lookup_type: null,
            amount: '',
            year: null,
            years: [],
            services: [],
        });
        setItems(copyItems);
    }
    const updateRow = (e, i, field) => {
        let copyItems = [...items];
        copyItems[i][field] = e.value;
        if (field === 'client_id') {
            copyItems[i]['years'] = e.fiscal_year.map((y) => { return { label: y, value: y } });
            copyItems[i]['services'] = e.lookups.map((y) => { return { label: y.lookup_name, value: y.lookup_id, type: y.type, service_id: y.service_id } });
        }
        if (field === 'lookup_id') {
            copyItems[i]['service_id'] = e.service_id;
            copyItems[i]['lookup_type'] = e.type;
        }
        setItems(copyItems);
    }
    const deleteItem = (i) => {
        let copyItems = [...items];
        copyItems.splice(i, 1);
        setItems(copyItems);
    }
    const saveInvoices = () => {
        if (formData.invoice_number == '' ||
            formData.credit_days == '' ||
            formData.invoice_date == '') {
            setError(true);
            return false;
        }
        setError(false);
        let total = 0;
        items.map((res) => {
            total = total + (res.amount ? parseInt(res.amount) : 0);
        });
        let body = {
            ...formData,
            total_amount: total,
            items: items
        };
        body.invoice_date = formData.invoice_date ? moment(formData.invoice_date).format("YYYY-MM-DD") : null;
        const pUrl = API_URL.SAVE_INVOICE;
        axios.post(pUrl, body).then((res) => {
            if (res.data.status === 1) {
                toast.success("Invoiced Generated", {
                    autoClose: 3000,
                    position: "top-center",
                });
                setAdd(false);
                getInvoiceList();
            }
            resetForm();
        })
    };
    useEffect(() => {
        getInvoiceList();
        getClient();
        getGroupClient();
    }, []);
    const Invoiceoptions = [
        { value: 'Client', label: 'Client' },
        { value: 'Group', label: 'Group' },
    ];
    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const isShowError = (value) => {
        if (isError && (!value || value == '' || value == null || value == undefined)) {
            return true;
        }
        return false;
    };
    const totalAmount = () => {
        let total = 0;
        items.map((res) => {
            total = total + (res.amount ? parseInt(res.amount) : 0);
        });
        return total.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        });
    };
    const getInvoiceList = () => {
        axios.post(API_URL.INVOICES, {}).then(res => {
            if (res.data.status === 1) {
                setInvoiceList(res.data.data);
                setCopyInvoiceList(res.data.data);
                setAllClientList(res.data.data)
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        let copyData = [...copyInvoiceList];
        if (sortByDir === 'ASC') {
            copyData.sort((a, b) => {
                return a.total_amount - b.total_amount;
            });
            setInvoiceList(copyData);
        }
        if (sortByDir === 'DESC') {

            copyData.sort((a, b) => {
                return b.total_amount - a.total_amount;
            });
            setInvoiceList(copyData);
        }
        if (sortByDir === '') {
            setInvoiceList(copyData);
        }
    }, [sortByDir]);

    const print = (element) => {
        navigate('/pdf/' + element.id);
    };
    const deleteInvoice = (res) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Want to delete?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(API_URL.DELETE_INVOICE, { id: res.id }).then(res => {
                    if (res.data.status === 1) {
                        getInvoiceList();
                        getClient();
                        getGroupClient();
                        toast.success('Successfully Invoice Deleted', {
                            autoClose: 3000,
                            position: "top-center",
                        });
                    } else {
                        toast.error(res.data.data, {
                            autoClose: 3000,
                            position: "top-center",
                        });
                    }
                });
            }
        })
    }
    const resetForm = () => {
        setFormData({
            id: null,
            client_id: '',
            group_id: '',
            invoice_number: "",
            credit_days: "",
            invoice_date: "",
            invoice_for: "",
            total_amount: "",
        });
        setItems([{
            client_id: '',
            lookup_id: '',
            year: '',
            amount: '',
        }]);
    }

    useEffect(() => {
        let copyData = [...allClientList];
        let selSearch = selectedColumns.filter((d) => d.search);
        if (selSearch.length > 0) {
            let filterArray = [];
            copyData.map((res) => {
                let flagArray = [];
                selSearch.map((filter) => {
                    if (res[filter.value]) {
                        let flag = res[filter.value].toLowerCase().indexOf(filter.search.toLowerCase()) > -1 ? true : false;
                        if (flag) {
                            flagArray.push(flag);
                        }
                    }
                });
                if (flagArray.length === selSearch.length) {
                    filterArray.push(res);
                }
            });
            setInvoiceList(filterArray);
        }
        else {
            setInvoiceList(allClientList);
        }
        setFilter(false);
    }, [selectedColumns, isFilter]);

    return (
        <>
            <div>
                {!isAdd && <button className=" float-right btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding mb-2" onClick={(() => setAdd(true))}>Add New Invoice</button>}
                {isAdd && <button className="btn btn-danger text-white ctm-border-radius float-right mb-2" onClick={(() => setAdd(false))}>Cancel</button>}
            </div>
            {isAdd && <>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row mb-4'>
                            <div className='col-md-3'>
                                <label className='form-label'>Invoice For</label>
                                <Select
                                    className={`${isShowError(formData.invoice_for) && 'required-field'}`}
                                    options={Invoiceoptions}
                                    value={formData.invoice_for ? (Invoiceoptions.find(r => r.value == formData.label)) : null}
                                    onChange={(e) => {
                                        handleSelectChange(e)
                                        setFormData({ ...formData, invoice_for: e.value })
                                    }}
                                />
                            </div>
                            {
                                selectedOption.value === "Client" && <div className='col-md-3'>
                                    <label className="form-label">Client Name</label>
                                    <Select
                                        options={clientList}
                                        value={formData.client_id ? (clientList.find(r => r.value == formData.client_id)) : null}
                                        onChange={(e) =>
                                            setFormData({ ...formData, client_id: e.value })
                                        }
                                    />
                                </div>
                            }
                            {
                                selectedOption.value === 'Group' && <div className='col-md-3'>
                                    <label className="form-label">Group Name</label>
                                    <Select
                                        options={groupList}
                                        value={formData.group_id ? (groupList.find(r => r.value == formData.group_id)) : null}
                                        onChange={(e) =>
                                            setFormData({ ...formData, group_id: e.value })
                                        }
                                    />
                                </div>
                            }
                            <div className='col-md-2 form-group'>
                                <label className="form-label">Invoice No</label>
                                <input
                                    type="text"
                                    className={`form-control ${isShowError(formData.invoice_number) && 'required-field'}`}
                                    name="invoice_number"
                                    value={formData.invoice_number}
                                    onChange={(e) => { setFormData({ ...formData, invoice_number: e.target.value }) }}
                                />
                            </div>
                            <div className='col-md-2 form-group'>
                                <label className="form-label">Credit Days</label>
                                <input
                                    type="text"
                                    className={`form-control ${isShowError(formData.credit_days) && 'required-field'}`}
                                    name="credit_days"
                                    value={formData.credit_days}
                                    onChange={(e) => { setFormData({ ...formData, credit_days: e.target.value }) }}
                                />
                            </div>
                            <div className='col-md-2'>
                                <label className='mb-0 form-label'>Invoice Date</label>
                                <DatePicker
                                    showIcon
                                    className={`form-control ${isShowError(formData.invoice_date) && 'required-field'}`}
                                    placeholderText='Select Date..'
                                    selected={formData.invoice_date}
                                    onChange={(date) => {
                                        setFormData((prevState) => ({ ...prevState, invoice_date: date }));
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className='mb-4 d-block'>
                            <div className='title d-inline-block'>Task Details</div>
                            <button type='button' className="float-right btn btn-theme ctm-border-radius text-white" onClick={addItem}><AiOutlinePlusCircle size={22} /></button>
                        </div>
                        <table className="table custom-table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr.No</th>
                                    <th>Client Name</th>
                                    <th>Task Name</th>
                                    <th>Year</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((itm, k) => {
                                    return <tr key={k}>
                                        <td>{k + 1}</td>
                                        <td>
                                            <Select
                                                menuPlacement="top"
                                                options={clientList}
                                                value={itm.client_id ? (clientList.find(r => r.value == itm.client_id)) : null}
                                                onChange={(e) => updateRow(e, k, 'client_id')}
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                menuPlacement="top"
                                                options={itm.services}
                                                value={itm.lookup_id ? (itm.services.find(r => r.value == itm.lookup_id)) : null}
                                                onChange={(e) => updateRow(e, k, 'lookup_id')}
                                            /></td>
                                        <td>
                                            {/* {itm.lookup_type} */}
                                            <Select
                                                menuPlacement="top"
                                                options={itm.years}
                                                value={itm.year ? (itm.years.find(r => r.value == itm.year)) : null}
                                                onChange={(e) => updateRow(e, k, 'year')}
                                            /></td>
                                        <td>
                                            <input
                                                type="number"
                                                className='form-control'
                                                name="credit_days"
                                                value={itm.amount ? itm.amount : ''}
                                                onChange={(e) => updateRow(e.target, k, 'amount')}
                                            />
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-red btn-action-table"
                                                onClick={() => deleteItem(k)}>
                                                <AiOutlineDelete size={25} />
                                            </button>
                                        </td>
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>

                        <div className='float-right'>
                            <div className='title'>
                                Total Amount : {totalAmount()}
                            </div>
                        </div>
                        <div className='d-flex w-100' style={{ justifyContent: 'flex-end' }}>
                            <button
                                className="float-right btn btn-theme ctm-border-radius text-white mt-2"
                                onClick={(() => {
                                    saveInvoices()
                                })}>
                                Invoice Generate
                            </button>
                        </div>
                    </div>
                </div>
            </>}
            {!isAdd &&
                <div className='card'>
                    <div className='card-body'>
                        {
                            loading ? <Oval
                                height={60}
                                width={60}
                                color="#7539ff"
                                wrapperStyle={{ justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#7539ff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                                :
                                <table className="table custom-table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '8%' }}>Invoice No</th>
                                            <th style={{ width: '10%' }}>Invoice Date</th>
                                            <th>Client Name / Group Name</th>
                                            <th className='text-right' style={{ width: '12%', cursor: 'pointer' }} onClick={() => {
                                                if (sortByDir === '') {
                                                    setSortByDir('ASC');
                                                } else if (sortByDir === 'ASC') {
                                                    setSortByDir('DESC');
                                                } else if (sortByDir === 'DESC') {
                                                    setSortByDir('');
                                                }
                                            }} >
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <div>Total Amount</div>
                                                    {sortByDir === 'ASC' && <FaSortNumericUp className='ml-1' />}
                                                    {sortByDir === 'DESC' && <FaSortNumericDownAlt className='ml-1' />}
                                                </div>
                                            </th>
                                            <th style={{ width: '10%' }} className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {selectedColumns.map((row, kd) => {
                                                return (
                                                    <td key={kd}>
                                                        <input
                                                            placeholder='Search...'
                                                            type="text"
                                                            style={{ width: '100%', minWidth: '100px', padding: '0px 8px' }}
                                                            className="form-control form-control-sm"
                                                            name="name"
                                                            value={row.search}
                                                            onChange={(e) => {
                                                                let copyData = [...selectedColumns];
                                                                copyData[kd].search = e.target.value;
                                                                setSelectedColumns(copyData);
                                                            }}
                                                        />
                                                    </td>
                                                )
                                            })}
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {invoiceList.map((res, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{res.invoice_number}</td>
                                                    <td>{moment(res.invoice_date).format('DD-MM-YYYY')}</td>
                                                    <td>{res?.client_name}</td>
                                                    <td className='text-right'>₹{res.total_amount}</td>
                                                    <td className='text-center'>
                                                        <AiOutlineDelete className='mr-1' style={{ cursor: 'pointer' }} size={25} onClick={() => deleteInvoice(res)} />
                                                        <AiOutlinePrinter style={{ cursor: 'pointer' }} size={25} onClick={() => print(res)} />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Invoice;
