import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import NoteModal from "../../component/modal/note-modal";
import TagFilter from "../../component/filter/filterByTag";
import { API_URL } from "../../const";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { GrNotes } from "react-icons/gr";
import { CiViewColumn } from "react-icons/ci";
import { FiFilter } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { Form } from "react-bootstrap";
import { AiOutlineTag } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import moment from "moment";

const ItReturn = () => {
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [itClient, setItClient] = useState([]);
  const [shownotes, setShowNotes] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [fiYear, setFiYear] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [allClientList, setAllClientList] = useState([]);
  const [isFilter, setFilter] = useState(false);
  const [showColum, setShowColum] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isStatus, setIsStatus] = useState([
    { id: "Blank", name: "Blank", checked: false },
    { id: "Received", name: "Received", checked: false },
    { id: "Suspense", name: "Suspense", checked: false },
    { id: "Tax Pending", name: "Tax Pending", checked: false },
    { id: "Done", name: "Done", checked: false },
    { id: "Okay", name: "Okay", checked: false },
    { id: "Filled", name: "Filled", checked: false },
    { id: "Print", name: "Print", checked: false },
    { id: "eVerified", name: "eVerified", checked: false },
  ]);
  const [selectedColumns, setSelectedColumns] = useState([
    { label: "File No", value: "file_no", search: "" },
    { label: "Trade Name", value: "firm_name", search: "" },
    { label: "Mobile No", value: "person_one_no", search: "" },
    { label: "Email", value: "email_one", search: "" },
  ]);
  const [columns, setColumns] = useState([
    { label: "Client Status", value: "is_active", search: "" },
    { label: "File No", value: "file_no", search: "" },
    { label: "Client Name", value: "name", search: "" },
    { label: "Firm Type", value: "firm_type", search: "" },
    { label: "Firm Name", value: "firm_name", search: "" },
    { label: "Address Line 1", value: "address_line_one", search: "" },
    { label: "Address Line 2", value: "address_line_two", search: "" },
    { label: "City", value: "city", search: "" },
    { label: "State", value: "state", search: "" },
    { label: "Pincode", value: "pincode", search: "" },
    { label: "Group Name", value: "group_name", search: "" },
    { label: "Sub Group Name", value: "sub_group_name", search: "" },
    { label: "Joining Date", value: "joining_date", search: "" },
    { label: "Contact Person-1 Name", value: "person_one_name", search: "" },
    { label: "Contact Person-1 Mobile No", value: "person_one_no", search: "" },
    { label: "Contact Person-1 Email", value: "email_one", search: "" },
    { label: "Contact Person-2 Name", value: "person_two_name", search: "" },
    { label: "Contact Person-2 Mobile No", value: "person_two_no", search: "" },
    { label: "Contact Person-2 Email", value: "email_two", search: "" },
    { label: "Mehtaji Name", value: "mehtaji_name", search: "" },
    { label: "Mehtaji No", value: "mehtaji_no", search: "" },
    { label: "PAN No", value: "pan_no", search: "" },
    { label: "PAN Password", value: "pan_password", search: "" },
    { label: "TAN No", value: "tan_no", search: "" },
    { label: "Trace Username", value: "trace_username", search: "" },
    { label: "Trace Password", value: "trace_password", search: "" },
    { label: "Income Tax Password", value: "income_tax_password", search: "" },
    { label: "GSTIN No", value: "gstin_no", search: "" },
    { label: "GSTIN Username", value: "gstin_username", search: "" },
    { label: "GSTIN Password", value: "gstin_password", search: "" },
    { label: "E-waybill Username", value: "ewaybill_username", search: "" },
    { label: "E-waybill Password", value: "ewaybill_password", search: "" },
    { label: "IEC No", value: "iec_no", search: "" },
    { label: "IEC Username", value: "iec_username", search: "" },
    { label: "IEC Password", value: "iec_password", search: "" },
    { label: "MSME No", value: "msme_no", search: "" },
  ]);
  const renderCheckBox = (row, key, filtertype) => {
    return (
      <Form key={filtertype + "-" + key}>
        {["checkbox"].map((type) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "15px",
            }}
            key={`${filtertype}-${row.id}`}
          >
            <Form.Check
              style={{ display: "flex", alignItems: "center" }}
              type={type}
              checked={row.checked}
              id={`${filtertype}-${row.id}`}
              label={row.name}
              onChange={(e) => {
                let items = [];
                if (filtertype === "Status") {
                  items = [...isStatus];
                  items[key].checked = e.target.checked;
                  setIsStatus(items);
                }
              }}
            />
          </div>
        ))}
      </Form>
    );
  };
  useEffect(() => {
    let today = new Date();
    let year;
    if (today.getMonth() < 3) {
      year = today.getFullYear() - 1 + "-" + today.getFullYear();
    } else {
      year = today.getFullYear() + "-" + (today.getFullYear() + 1);
    }
    setFiYear(year);
    getItrClient(year);
  }, []);

  const hideShowColumns = (e) => {
    setSelectedColumns(e);
  };

  const getItrClient = (year) => {
    axios.post(API_URL.CLIENT_ITRETURN, { year: year }).then((res) => {
      if (res.data.status === 1) {
        res.data.data.map((row) => {
          if (!row.status) {
            row.status = "Blank";
          }
          if (row.reminder_date) {
            row.reminder_date = new Date(row.reminder_date);
          }
        });
        setItClient(res.data.data);
        setAllClientList(res.data.data);
      } else {
        toast.error(res.data.data, "", {
          autoClose: 3000,
          position: "top-center",
        });
      }
      setLoading(false);
    });
  };

  const handleItrStatus = (e, key, res) => {
    let payload = {
      lookup_id: 1,
      client_id: res.id,
      year: fiYear,
      status: e.target.value,
    };
    let copyClient = [...itClient];
    copyClient[key].status = payload.status;
    setItClient(copyClient);

    let copyAllClient = [...allClientList];
    let index = copyAllClient.findIndex((d)=> d.id === copyClient[key].id);
    copyAllClient[index].status = payload.status;
    setAllClientList(copyAllClient);

    updateData(payload, key);
  };

  const updateData = (payload) => {
    axios.post(API_URL.CLIENT_ITRETURN_STATUS_UPDATE, payload).then((res) => {
      if (res.data.status !== 1) {
        toast.error(res.data.data, "", {
          autoClose: 3000,
          position: "top-center",
        });
      }
    });
  };

  const handleShowNotes = (note) => {
    setCurrentRow(note);
    setShowNotes(true);
  };

  const tagFilter = (obj) => {
    let flag = false;
    selectedTags.map((res) => {
      let sel = obj.client_tags.find((d) => d.id === res.value);
      if (sel) {
        flag = true;
      }
    });
    return flag;
  };

  const getFyYearRange = () => {
    let currentYear = moment().format("YY");
    let previousYear = moment().subtract(1, "years").format("YYYY");
    return previousYear + "-" + currentYear;
  };
  const getFyDateRange = () => {
    let currentYear = moment().format("YYYY");
    let previousYear = moment().subtract(1, "years").format("YYYY");
    return `01.04.${previousYear} થી 31.03.${currentYear}`;
  };

  const messageFormat = (element) => {
    return `Shri *${
      element.name
    }*,વર્ષ *${getFyYearRange()}* ના ઇનકમ ટૅક્સ ભરવા માટે %0a*1.* તા-*${getFyDateRange()}* સુધીની બધી બૅન્ક ની પાસબૂક અપડેટ કરેલી.%0a*2.* જો લોન ચાલુ હોય તો લોનનું સ્ટેટમેંટ.%0a*3.* LIC, School Fees ની રિસીપ્ટ.%0a*4.* નવી કોઈ property લીધી હોય તો તેનો દસ્તાવેજ અથવા બિલ.%0a*5.* જો ખેતીની જમીન હોય તો તેના ૭-૧૨.%0aઉપર જણાવેલ ડિટેલ અમારી ઓફિસ એ પહોચડવા વિનંતી.%0aખૂબ ખૂબ ધન્યવાદ. કઈ પ્રશ્ન માટે કોલ કરો *7016537879 / 9099183346 / 9913105390*%0aFrom *_SANJA %26 CO_*`;
  };

  useEffect(() => {
    let copyData = [...allClientList];
    let selSearch = selectedColumns.filter((d) => d.search);
    let selChecked = isStatus.filter((d) => d.checked);
    let status = selChecked.map((res) => {
      return res.id;
    });
    if (selSearch.length > 0) {
      let filterArray = [];
      copyData.map((res) => {
        let flagArray = [];
        selSearch.map((filter) => {
          if (res[filter.value]) {
            let flag =
              res[filter.value]
                .toLowerCase()
                .indexOf(filter.search.toLowerCase()) > -1
                ? true
                : false;
            if (flag) {
              if (status.length > 0) {
                status.includes(res.status) && flagArray.push(flag);
              } else {
                flagArray.push(flag);
              }
            }
          }
        });
        if (flagArray.length === selSearch.length) {
          filterArray.push(res);
        }
      });
      setItClient(filterArray);
    } else {
      let sel = copyData.filter((d) => {
        return status.includes(d.status) || tagFilter(d);
      });
      if (sel.length === 0) {
        setItClient(allClientList);
      } else {
        setItClient(sel);
      }
    }
    setFilter(false);
  }, [selectedColumns, isFilter, selectedTags]);

  return (
    <>
      <div className="w-100">
        <div className="ctm-border-radius shadow-sm card">
          <div className="card-body align-center">
            <h4 className="title card-title float-left">
              {itClient.length} Records
            </h4>
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              {showColum && (
                <div className="row">
                  <div className="col">
                    <Select
                      isClearable={false}
                      className="basic-multi-select"
                      placeholder="Select columns which you want to display"
                      isMulti={true}
                      name="columns"
                      options={columns}
                      value={selectedColumns}
                      onChange={(e) => hideShowColumns(e)}
                    />
                  </div>
                </div>
              )}
              <Modal
                show={modalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header className="modal-header-badges">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="title">Filter By Status</div>
                  </div>
                  <RxCross2
                    size={25}
                    onClick={() => {
                      setModalShow(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Modal.Header>
                <Modal.Body className="filter-modal">
                  {isStatus.map((row, key) => {
                    return (
                      <div key={key}>{renderCheckBox(row, key, "Status")}</div>
                    );
                  })}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-danger text-white ctm-border-radius float-right mr-2"
                    onClick={() => {
                      let copyData = [...isStatus];
                      copyData.map((res) => {
                        res.checked = false;
                      });
                      setIsStatus(copyData);
                      setFilter(true);
                      setModalShow(false);
                    }}
                  >
                    Clear
                  </button>
                  <button
                    className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding"
                    onClick={() => {
                      setFilter(true);
                      setModalShow(false);
                    }}
                  >
                    Apply
                  </button>
                </Modal.Footer>
              </Modal>
              <div className="employee-office-table">
                {loading ? (
                  <Oval
                    height={60}
                    width={60}
                    color="#7539ff"
                    wrapperStyle={{ justifyContent: "center" }}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#7539ff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  <div className="table-responsive mt-3">
                    <div className="table-responsive">
                      <table className="table custom-table table-bordered">
                        <thead>
                          <tr>
                            {selectedColumns.map((row, kd) => {
                              return <th key={kd}>{row.label}</th>;
                            })}
                            <th>IT Return Status</th>
                            <th>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div>Action</div>
                                <div>
                                  {" "}
                                  <CiViewColumn
                                    style={{ textAlign: "end" }}
                                    color="white"
                                    cursor={"pointer"}
                                    size={22}
                                    onClick={() => setShowColum(!showColum)}
                                  />
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {selectedColumns.map((row, kd) => {
                              return (
                                <td key={kd}>
                                  <input
                                    placeholder="Search..."
                                    type="text"
                                    style={{
                                      width: "100%",
                                      minWidth: "100px",
                                      padding: "0px 3px",
                                    }}
                                    className="form-control form-control-sm"
                                    name="name"
                                    value={row.search}
                                    onChange={(e) => {
                                      let copyData = [...selectedColumns];
                                      copyData[kd].search = e.target.value;
                                      setSelectedColumns(copyData);
                                    }}
                                  />
                                </td>
                              );
                            })}
                            <td className="text-center">
                              <FiFilter
                                color={
                                  isStatus.filter((d) => d.checked).length > 0
                                    ? "blue"
                                    : "black"
                                }
                                cursor={"pointer"}
                                size={22}
                                onClick={() => setModalShow(true)}
                              />
                            </td>
                            <td className="text-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-red btn-action-table"
                                onClick={() => {
                                  setIsShow(true);
                                }}
                              >
                                <AiOutlineTag size={25} />
                              </button>
                            </td>
                          </tr>
                          {itClient.map((res, key) => {
                            return (
                              <tr key={key}>
                                {selectedColumns.map((row, lk) => {
                                  return (
                                    <td key={key + "" + lk}>
                                      {row.value === "firm_name" ? (
                                        <>
                                          {res[row.value]}
                                          <div>
                                            {res.client_tags &&
                                              res.client_tags.map((el, tk) => {
                                                return (
                                                  <span
                                                    key={tk}
                                                    style={{
                                                      backgroundColor:
                                                        "#511989",
                                                    }}
                                                    className="badge badge-primary mr-1"
                                                  >
                                                    {el.name}
                                                  </span>
                                                );
                                              })}
                                          </div>
                                        </>
                                      ) : (
                                        <>{res[row.value]}</>
                                      )}
                                    </td>
                                  );
                                })}
                                <td>
                                  <div className="col form-group pl-0 pr-0 ml-0 mr-0 mb-0 ">
                                    <select
                                      value={res.status ? res.status : ""}
                                      name="itrstatus"
                                      id="itrstatus"
                                      className="form-control select "
                                      onChange={(e) =>
                                        handleItrStatus(e, key, res)
                                      }
                                    >
                                      <option value="Blank">Blank</option>
                                      <option value="Received">Received</option>
                                      <option value="Suspense">Suspense</option>
                                      <option value="Tax Pending">
                                        Tax Pending
                                      </option>
                                      <option value="Done">Done</option>
                                      <option value="Okay">Okay</option>
                                      <option value="Filled">Filled</option>
                                      <option value="Print">Print</option>
                                      <option value="eVerified">
                                        eVerified
                                      </option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="action-section">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-red btn-action-table"
                                      onClick={() => {
                                        handleShowNotes(res);
                                      }}
                                    >
                                      <GrNotes size={22} />
                                    </button>

                                    <a
                                      href={`https://web.whatsapp.com/send/?phone=91${
                                        res.person_one_no
                                      }&text=${messageFormat(
                                        res
                                      )}&type=phone_number&app_absent=0`}
                                      rel="nofollow noopener"
                                      target="_blank"
                                      className="share-icon"
                                    >
                                      <FaWhatsapp color="green" size={25} />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              <TagFilter
                open={isShow}
                onClose={(tags) => {
                  setIsShow(false);
                  if (tags) {
                    setSelectedTags(tags);
                  }
                }}
              ></TagFilter>
              <NoteModal
                firmType={"ItReturn"}
                openNotes={shownotes}
                note={currentRow}
                onCloseNotes={() => setShowNotes(false)}
              ></NoteModal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItReturn;
