import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import moment from 'moment';
import { API_URL } from '../../../const';
import { toast } from 'react-toastify';
import { Oval } from "react-loader-spinner";
import { AiOutlineDelete } from 'react-icons/ai';

const CashAccount = () => {

    const [cashList, setCashList] = useState([]);
    const [loading, setLoading] = useState(true);

    const year = [
        { value: '2022-2023', label: '2022-2023' },
        { value: '2023-2024', label: '2023-2024' },
    ];
    
    const getCashData = () => {
        axios.post(API_URL.CASH_BANK_LIST, { payment_type: "Cash" }).then(
            (res) => {
                if (res.data.status === 1) {
                    setCashList(res.data.data);
                }
                setLoading(false)
            },
            (error) => {
                toast.error("Something went wrong..", {
                    autoClose: 3000,
                    position: "top-center",
                });
                setLoading(false)
            }
        );
    };

    const deleteData = (res) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085D6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(API_URL.LEDGER_DELETE, { id: res.id }).then(
                    (res) => {
                        if (res.data.status === 1) {
                            getCashData();
                        } else {
                            toast.error(res.data.data, {
                                autoClose: 3000,
                                position: "top-center"
                            });
                        }
                    },
                );
            }
        });
    };

    useEffect(() => {
        getCashData();
    }, []);

    return (
        <div className='mt-3'>
            {/* <div className='mb-2 w-50'>
                <label className='form-label'>Select Period</label>
                <Select
                    options={year}
                // value={selectedOption}
                // onChange={handleSelectChange}
                />
            </div> */}
            {
                loading ? <Oval
                    height={60}
                    width={60}
                    color="#7539ff"
                    wrapperStyle={{ justifyContent: "center" }}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#7539ff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
                    :
                    <div className="table-responsive">
                        <table className="table custom-table table-bordered mt-4">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>Payment Date</th>
                                    <th style={{ width: "10%" }}>Payment Mode</th>
                                    <th style={{ width: "40%" }}>Description</th>
                                    <th style={{ width: "20%" }}>Remark</th>
                                    <th style={{ width: "10%" }}>Credit</th>
                                    <th style={{ width: "10%" }}>Debit</th>
                                    <th style={{ width: "10%" }}>Amount</th>
                                    <th className='text-center' style={{ width: "10%" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cashList.map((res, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{moment(res.date).format('DD/MM/YYYY')}</td>
                                            <td>{res.ledger_type}</td>
                                            <td>{res.remark}</td>
                                            <td>{res.received_by}</td>
                                            <td>{(res.amount_for == 'Cr') ? res.amount : ''}</td>
                                            <td>{(res.amount_for == 'Dr') ? res.amount : ''}</td>
                                            <td>{res.balance} {res.balance_mode}</td>
                                            <td>
                                                <div className="action-section">
                                                    <button type="button" className="btn btn-sm btn-red btn-action-table" onClick={()=>{deleteData(res)}}><AiOutlineDelete size={25} /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default CashAccount
