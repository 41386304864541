import React, { useState } from "react";
import Tags from "../tags/tags";
import Groups from "../groups/groups";
import OtherContact from "../other-contact/other-contact";
import NonRoutineTask from "../non-routine-task/non-routine-task";

const Settings = () => {
  const [mode, setMode] = useState(1);
  return (
    <>
      <div className="w-100">
        <div className="card shadow-sm ctm-border-radius">
          <div className="card-body">
            <div
              className="flex-row list-group list-group-horizontal-lg"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className=" active list-group-item"
                id="v-pills-home-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-home"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
                onClick={() => {
                  setMode(1);
                }}
              >
                Tags
              </a>
              <a
                className="list-group-item"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => {
                  setMode(2);
                }}
              >
                Groups
              </a>
              <a
                className="list-group-item"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => {
                  setMode(3);
                }}
              >
                Other Contact
              </a>
              <a
                className="list-group-item"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="reviews.html#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => {
                  setMode(4);
                }}
              >
                Non Routine
              </a>
            </div>
          </div>
        </div>
        {mode === 1 && <Tags />}
        {mode === 2 && <Groups />}
        {mode === 3 && <OtherContact />}
        {mode === 4 && <NonRoutineTask />}
      </div>
    </>
  );
};

export default Settings;
