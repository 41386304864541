import React, { useState, useEffect } from "react";
import {
  FaSortNumericUp,
  FaSortNumericDownAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { API_URL } from "../../const";
import axios from "axios";

const LedgerList = ({ handleClick }) => {
  const [ledgersList, setLedgersList] = useState([]);
  const [allLegderList, setAllLegderList] = useState([]);
  const [isFilter, setFilter] = useState(false);
  const [sortByDir, setSortByDir] = useState("");
  const [selectedColumns, setSelectedColumns] = useState([
    { label: "Client Name", value: "name", search: "" },
    { label: "Contact Number", value: "contact_number", search: "" },
    { label: "Closing Balance", value: "amount", search: "" },
  ]);

  useEffect(() => {
    getLedgers();
  }, []);

  const getLedgers = () => {
    axios.post(API_URL.LEDGER_LIST, {}).then((res) => {
      setLedgersList(res.data.data);
      setAllLegderList(res.data.data);
    });
  };

  const messageFormat = (element)=>{
    return `Shri *${element.name}*,%0a2023/24 ના ઇન્કમ ટેક્સ રિટર્ન ના GPay 9913105390 કરી આપો _*₹${element.amount} ${element.type}*_ .%0a*Sanja %26 co*`;
  };

  useEffect(() => {
    let copyData = [...allLegderList];
    let selSearch = selectedColumns.filter((d) => d.search);
    if (selSearch.length > 0) {
      let filterArray = [];
      copyData.map((res) => {
        let flagArray = [];
        selSearch.map((filter) => {
          if (res[filter.value]) {
            let valueStr = isNaN(res[filter.value])
              ? res[filter.value].toLowerCase()
              : res[filter.value].toString();
            let serachStr = isNaN(filter.search)
              ? filter.search.toLowerCase()
              : filter.search;
            let flag = valueStr.indexOf(serachStr) > -1 ? true : false;
            if (flag) {
              flagArray.push(flag);
            }
          }
        });
        if (flagArray.length === selSearch.length) {
          filterArray.push(res);
        }
      });
      setLedgersList(filterArray);
    } else {
      setLedgersList(allLegderList);
    }
    setFilter(false);
  }, [selectedColumns, isFilter]);

  useEffect(() => {
    let copyData = [...allLegderList];
    if (sortByDir === "ASC") {
      copyData.sort((a, b) => {
        return a.amount - b.amount;
      });
      setLedgersList(copyData);
    }
    if (sortByDir === "DESC") {
      copyData.sort((a, b) => {
        return b.amount - a.amount;
      });
      setLedgersList(copyData);
    }
    if (sortByDir === "") {
      setLedgersList(copyData);
    }
  }, [sortByDir]);

  return (
    <>
      <table className="table table-hover custom-table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th style={{ width: "200px" }}>Contact Number</th>
            <th
              className="text-right"
              style={{ width: "200px" }}
              onClick={() => {
                if (sortByDir === "") {
                  setSortByDir("ASC");
                } else if (sortByDir === "ASC") {
                  setSortByDir("DESC");
                } else if (sortByDir === "DESC") {
                  setSortByDir("");
                }
              }}
            >
              <div className="d-flex align-items-center justify-content-end">
                <div>Closing Balance</div>
                {sortByDir === "ASC" && <FaSortNumericUp className="ml-1" />}
                {sortByDir === "DESC" && (
                  <FaSortNumericDownAlt className="ml-1" />
                )}
              </div>
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {selectedColumns.map((row, kd) => {
              return (
                <td key={kd}>
                  <input
                    placeholder="Search..."
                    type="text"
                    style={{
                      width: "100%",
                      minWidth: "100px",
                      padding: "0px 8px",
                    }}
                    className="form-control form-control-sm"
                    name="name"
                    value={row.search}
                    onChange={(e) => {
                      let copyData = [...selectedColumns];
                      copyData[kd].search = e.target.value;
                      setSelectedColumns(copyData);
                    }}
                  />
                </td>
              );
            })}
            <td></td>
          </tr>
          {ledgersList.map((led, k) => {
            return (
              <tr key={k}>
                <td className="text-primary pointer" onClick={() => handleClick(led)}>{led.name}</td>
                <td onClick={() => handleClick(led)}>{led.contact_number}</td>
                <td onClick={() => handleClick(led)} className="text-right">
                  {Math.abs(led.amount)} {led.type}
                </td>
                <td className="text-center" style={{ cursor: "pointer" }}>
                  <a
                    href={`https://web.whatsapp.com/send/?phone=91${led.contact_number}&text=${messageFormat(led)}&type=phone_number&app_absent=0`}
                    rel="nofollow noopener"
                    target="_blank"
                    className="share-icon"
                  >
                    <FaWhatsapp color="green" size={25} />
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default LedgerList;
