import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../const';
import { Oval } from "react-loader-spinner";
import "react-datepicker/dist/react-datepicker.css";

const PendingInvoice = () => {
  const [loading, setLoading] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [allClientList, setAllClientList] = useState([]);
  const [isFilter, setFilter] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([
    { label: "Client Name", value: "name", search: "" },
    { label: "Trade Name", value: "firm_name", search: "" },
    // { label: "Task", value: "lookup_name", search: "" },
    // { label: "Year", value: "fiscal_year", search: "" },
  ]);

  const getClient = () => {
    axios.post(API_URL.PENDING_INVOICE, {}).then(res => {
      if (res.data.status === 1) {
        setClientList(res.data.data);
        setAllClientList(res.data.data);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    getClient();
  }, []);

  useEffect(() => {
    let copyData = [...allClientList];
    let selSearch = selectedColumns.filter((d) => d.search);
    if (selSearch.length > 0) {
      let filterArray = [];
      copyData.map((res) => {
        let flagArray = [];
        selSearch.map((filter) => {
          if (res[filter.value]) {
            let flag = res[filter.value].toLowerCase().indexOf(filter.search.toLowerCase()) > -1 ? true : false;
            if (flag) {
              flagArray.push(flag);
            }
          }
        });
        if (flagArray.length === selSearch.length) {
          filterArray.push(res);
        }
      });
      setClientList(filterArray);
    }
    else {
      setClientList(allClientList);
    }
    setFilter(false);
  }, [selectedColumns, isFilter]);

  return (
    <div className='w-100'>
      <div className='card shadow-sm ctm-border-radius'>
        <div className='card-body'>
          {
            loading ? <Oval
              height={60}
              width={60}
              color="#7539ff"
              wrapperStyle={{ justifyContent: "center" }}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#7539ff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
              :
              <table className="table custom-table table-bordered">
                <thead>
                  <tr>
                    <th>Client Name</th>
                    <th>Trade Name</th>
                    <th>Task</th>
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {selectedColumns.map((row, kd) => {
                      return (
                        <td key={kd}>
                          <input
                            placeholder='Search...'
                            type="text"
                            style={{ width: '100%', minWidth: '100px', padding: '0px 8px' }}
                            className="form-control form-control-sm"
                            name="name"
                            value={row.search}
                            onChange={(e) => {
                              let copyData = [...selectedColumns];
                              copyData[kd].search = e.target.value;
                              setSelectedColumns(copyData);
                            }}
                          />
                        </td>
                      )
                    })}
                    <td></td>
                    <td></td>
                  </tr>
                  {clientList.map((row, k) => {
                    return <tr key={k}>
                      <td>{row.name}</td>
                      <td>{row.firm_name}</td>
                      <td>{row.services.toString()}</td>
                      <td>{row.fiscal_year.toString()}</td>
                    </tr>
                  })}
                </tbody>
              </table>
          }
        </div>
      </div>
    </div>
  )
}

export default PendingInvoice;
