import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../const";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";

const Dashboard = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [noteList, setNoteList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [searchTagBy, setSearchTagBy] = useState("");

  const getClient = (year) => {
    let temp = [];
    axios.post(API_URL.CLIENT_LIST, { year: year }).then((res) => {
      if (res.data.status === 1) {
        res.data.data.map((element) => {
          temp.push({
            person_one_no: element.person_one_no,
            email_one: element.email_one,
            firm_name: element.firm_name,
          });
        });
        getOtherContact(temp);
      }
    });
  };

  const getOtherContact = (temp) => {
    axios.post(API_URL.CONTACTS_LIST, {}).then((res) => {
      if (res.data.status === 1) {
        res.data.data.map((element) => {
          temp.push({
            person_one_no: element.contact_no,
            email_one: element.email,
            firm_name: element.name,
          });
        });
        setClientList(temp);
      }
    });
  };

  const listData = () => {
    setLoading(true);
    axios.post(API_URL.NOTES_REMINDER, {}).then(
      (res) => {
        if (res.data.status === 1) {
          setNoteList(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading(false);
      }
    );
  };

  const closeNote = (res) => {
    Swal.fire({
      title: "Are you sure you want to close this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(API_URL.NOTES_UPDATE, {
            id: res.id,
            status: 1,
          })
          .then((response) => {
            if (response.data.status === 1) {
              listData();
            } else {
              if (response.data && response.data.data) {
                toast.error("something Went Wromg.....", {
                  autoClose: 3000,
                  position: "top-center",
                });
              }
            }
          });
      }
    });
  };

  const updateReminderDate = (id, date) => {
    let url = API_URL.NOTES_UPDATE;
    axios
      .post(url, {
        id: id,
        date: date ? moment(date).format("YYYY-MM-DD") : null,
      })
      .then((response) => {
        if (response.data.status === 1) {
          listData();
        } else {
          if (response.data && response.data.data) {
            toast.error("something Went Wromg.....", {
              autoClose: 3000,
              position: "top-center",
            });
          }
        }
      });
  };

  const isToday = (date) => {
    if (moment().format("YYYY-MM-DD") === date) {
      return "red";
    } else {
      return null;
    }
  };

  const filterClient = () => {
    let sel = clientList.filter(
      (d) => d.firm_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );
    return sel;
  };

  const getEmployee = () => {
    axios.post(API_URL.EMP_LIST, {}).then((res) => {
      let employess = [];
      res.data.data.map((element) => {
        employess.push({ value: element.name, label: element.name });
      });
      setEmployeeList(employess);
    });
  };

  const styles = {
    menu: (basestyle, state) => ({
      ...basestyle,
      zIndex: 999,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
        color: "#000",
      };
    },
  };

  const filterNoteData = ()=>{
    if(searchTagBy && searchTagBy.value){
      return noteList.filter((d)=> d.tag_by === searchTagBy.value);
    }
    return noteList;
  };

  useEffect(() => {
    listData();
    getEmployee();
    getClient();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12 d-flex">
        <div className="card flex-fill team-lead shadow-sm">
          <div className="card-header">
            <h4 className="card-title mb-0 d-inline-block">Reminder</h4>
          </div>
          <div className="card-body">
            {loading ? (
              <Oval
                height={60}
                width={60}
                color="#7539ff"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7539ff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-3">
                    <label className="mb-0 form-label">Search Tag By</label>
                    <Select
                      className="basic-single mb-3"
                      name="color"
                      styles={styles}
                      isClearable={true}
                      options={employeeList}
                      placeholder="Select"
                      value={searchTagBy}
                      onChange={(e) => {
                        setSearchTagBy(e);
                      }}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table custom-table table-bordered">
                    <thead>
                      <tr>
                        <th>Reminder Date</th>
                        <th>Tag By</th>
                        <th>Client Name</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                        <th>Firm Type</th>
                        <th>Note Type</th>
                        <th>Note</th>
                        <th>Created By</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterNoteData().map((res, key) => {
                        return (
                          <tr
                            key={key}
                            className={`${
                              isToday(res.date) && "today-reminder"
                            }`}
                          >
                            <td>
                              <div
                                className="date"
                                style={{ cursor: "pointer" }}
                              >
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Select Date.."
                                  selected={
                                    res.date ? new Date(res.date) : null
                                  }
                                  customInput={
                                    <div className="date">
                                      {moment(res.date).format("DD/MM/YYYY")}
                                    </div>
                                  }
                                  onChange={(date) => {
                                    const copyData = [...noteList];
                                    copyData[key].date = date;
                                    setNoteList(copyData);
                                    updateReminderDate(res.id, date);
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <span
                                class="badge badge-primary mr-1"
                                style={{
                                  backgroundColor: "rgb(81, 25, 137)",
                                  fontSize: "14px",
                                }}
                              >
                                {res.tag_by}
                              </span>
                            </td>
                            <td>{res.client?.firm_name}</td>
                            <td>{res.client?.person_one_no}</td>
                            <td>{res.client?.email_one}</td>
                            <td>{res.firm_type}</td>
                            <td>{res.note_type}</td>
                            <td
                              dangerouslySetInnerHTML={{ __html: res.notes }}
                            ></td>
                            <td>{res.user}</td>
                            <td>
                              <div className="action-section">
                                <button
                                  type="button"
                                  onClick={() => {
                                    closeNote(res);
                                  }}
                                  className="btn btn-sm btn-red btn-action-table "
                                >
                                  <AiOutlineCloseCircle
                                    color={isToday(res.date) && "white"}
                                    size={25}
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="search-section">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search By Client"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon1">
                <BsSearch />
              </span>
            </div>
          </div>
        </div>
      </div>
      {searchText && (
        <div className="card-section">
          {filterClient().map((res, key) => {
            return (
              <div className="card" key={key}>
                <div className="titles">{res.firm_name}</div>
                <div>{res.person_one_no}</div>
                <div>{res.email_one}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
