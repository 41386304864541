import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import CustomeRouter from './router/CustomeRouter';
import { ToastContainer } from 'react-toastify';
import 'react-tagsinput/react-tagsinput.css'
import 'react-date-picker/dist/DatePicker.css';

const App = () => {
  return (
    <>
      <CustomeRouter />
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
