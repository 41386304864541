import React from "react"
import { Route, Routes, Outlet } from "react-router-dom";
import Layout from "../layout/layout";
import CustomeRoutes from "./CustomeRoutes"

const Login = React.lazy(() => import("../component/login/login"));

const CustomeRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/login" element={<Login />} />
            <Route path="" element={<Outlet />} >
                {
                    CustomeRoutes.map((element) => {
                        return (
                            <Route key={element} path={element.path} element={<Layout component={element.component} />} />
                        );
                    })
                }
            </Route>
            <Route exact path="*" element={<h1>Not Found</h1>} />
        </Routes>

        // <Routes>
        //     <Route path="/" element={<Suspense fallback=""><Login /></Suspense>} />
        //     <Route path="/login" element={<Suspense fallback=""><Login /></Suspense>} />
        //     <Route path="/dashboard" element={<Suspense fallback=""><Dashboard /></Suspense>} />
        //     <Route path="/employee" element={<Suspense fallback=""><Employee /></Suspense>} />
        //     <Route path="/client" element={<Suspense fallback=""><Client /></Suspense>} />
        //     <Route path="/add-client" element={<Suspense fallback=""><AddClient /></Suspense>} />
        //     <Route path="/add-client/:id" element={<Suspense fallback=""><AddClient /></Suspense>} />
        //     <Route path="component" element={<Outlet />} >
        //         {
        //             CustomeRoutes.map((element) => {
        //                 return (
        //                     <Route key={element} path={element.path} element={<Layout component={element.component} />} />
        //                 );
        //             })
        //         }
        //     </Route>
        //     <Route exact path="*" element={<h1>Not Found</h1>} />
        // </Routes>
    )
}
export default React.memo(CustomeRouter);