import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { API_URL } from '../../../const';
import { toast } from 'react-toastify';

const Contra = () => {
    const [clientList, setClientList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [isError, setError] = useState(false);

    const getClient = () => {
        axios.post(API_URL.CLIENT_LIST, {}).then(res => {
            let ct = res.data.data.map((raw) => {
                return {
                    label: raw.firm_name,
                    value: raw.id
                }
            });
            setClientList(ct)
        });
    }

    const getGroupClient = () => {
        axios.post(API_URL.GROUPS_LIST, {}).then(res => {
            let ct = res.data.data.map((raw) => {
                return {
                    label: raw.name,
                    value: raw.id
                }
            });
            setGroupList(ct)
        });
    }

    const [formData, setFormData] = useState({
        date: "",
        amount: "",
        remark: "",
        received_by: "",
        payment_mode: "",
        client_id: "",
        ledger_for: "",
        group_id: "",
        payment_date: "",
    });

    const Invoiceoptions = [
        { value: 'Client', label: 'Client' },
        { value: 'Group', label: 'Group' },
    ];

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const saveLedger = () => {
        console.log(formData);
        if (formData.date == '' ||
            formData.ledger_for == '' ||
            formData.amount == '') {
            setError(true);
            return false;
        }
        setError(false);
        let body = { ...formData };
        body.date = formData.date ? moment(formData.date).format("YYYY-MM-DD") : null;
        const pUrl = API_URL.LEDGER_TRANSFER;
        axios.post(pUrl, body).then((res) => {
            if (res.data.status === 1) {
                toast.success("Contra Generated", {
                    autoClose: 3000,
                    position: "top-center",
                });
                resetForm();
            } else {
                resetForm()
            }
        })
    }

    const resetForm = () => {
        setFormData({
            id: null,
            date: "",
            amount: "",
            remark: "",
            received_by: "",
            payment_mode: "",
            client_id: "",
            ledger_for: "",
            group_id: "",
        })
    }

    const payment = [
        { value: 'Cash To Bank', label: 'Cash To Bank' },
        { value: 'Bank To Cash', label: 'Bank To Cash' }
    ];

    useEffect(() => {
        getClient();
        getGroupClient();
    }, []);


    return (
        <div className='mt-3'>
            <div className="accountant-receipt-container">
                <div className="accountant-receipt-header" style={{ borderBottom: '2px solid black' }}>
                    <div className='title'>Add Contra</div>
                </div>
                <div>
                    <div className="receipt-details">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <strong className='mr-3' style={{ width: "34%" }}>Date:</strong>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className='form-control w-34'
                                placeholderText='Select Date..'
                                selected={formData.date}
                                onChange={(date) => {
                                    setFormData((prevState) => ({ ...prevState, date: date }));
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <strong style={{ width: '25%' }} className='mr-3'>Select Payment Method</strong>
                            <Select
                                className='w-25 mt-2'
                                options={payment}
                                value={formData.ledger_for ? (payment.find(r => r.value == formData.ledger_for)) : null}
                                onChange={(e) =>
                                    setFormData({ ...formData, ledger_for: e.value })
                                }
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <strong style={{ width: "25%" }} className='mr-3'>Amount Paid</strong>
                            <input
                                type="number    "
                                className='form-control w-25 mt-2'
                                name="credit_days"
                                value={formData.amount}
                                onChange={(e) => { setFormData({ ...formData, amount: e.target.value }) }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <strong style={{ width: "25%" }} className='mr-3'>Description</strong>
                            <input
                                type="text"
                                className='form-control  w-25 mt-2'
                                name="description"
                                value={formData.remark}
                                onChange={(e) => { setFormData({ ...formData, remark: e.target.value }) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-2'>
                <button className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding" style={{ float: 'right' }} onClick={() => saveLedger()}>Submit</button>
                <button className="mr-2 btn btn-danger text-white ctm-border-radius float-right" style={{ float: 'right' }} onClick={() => resetForm()}>Reset</button>
            </div>
        </div>
    )
}

export default Contra;
