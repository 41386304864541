import React, { useEffect, useState } from 'react';
import { API_URL } from '../../const';
import { toast } from 'react-toastify';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import LedgerView from "./ledgerView";
import LedgerList from "./ledgerList";
import moment from 'moment';

const Ledger = () => {
    const [ledgers, setLedgers] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [showLedger, setShowLedger] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [detailMode, setDetailMode] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [formData, setFormData] = useState({
        ledger_for: "",
        amount_for: "",
        date: "",
        amount: "",
        client_id: "",
        group_id: "",
    })

    const getClient = () => {
        axios.post(API_URL.CLIENT_LIST, {}).then(res => {
            let ct = res.data.data.map((raw) => {
                return {
                    label: raw.firm_name,
                    value: raw.id
                }
            });
            setClientList(ct)
        });
    }
    const getGroupClient = () => {
        axios.post(API_URL.GROUPS_LIST, {}).then(res => {
            let ct = res.data.data.map((raw) => {
                return {
                    label: raw.name,
                    value: raw.id
                }
            });
            setGroupList(ct)
        });
    }
    const getLedgers = () => {
        axios.post(API_URL.LEDGER_LIST, {}).then(res => {
            setLedgers(res.data.data);
            const sum = res.data.data.reduce((accumulator, object) => {
                return accumulator + object.amount;
            }, 0);
            setTotalAmount(sum)
        });
    }

    useEffect(() => {
        getClient();
        getGroupClient();
        getLedgers();
    }, []);

    const Ledger = [
        { value: 'Client', label: 'Client' },
        { value: 'Group', label: 'Group' },
    ];

    const year = [
        { value: '2022-2023', label: '2022-2023' },
        { value: '2023-2024', label: '2023-2024' },
    ];

    const optionsCr = [
        { value: 'Cr', label: 'CR' },
        { value: 'Dr', label: 'DR' },
    ];

    const handleViewClick = (row) => {
        setSelectedRow(row)
        setDetailMode(true)
    }
    const goBack = () => {
        setDetailMode(false)
        setSelectedRow(null)
    }

    const saveOpeningBalance = () => {

        if (formData.date == '' || formData.ledger_for == '' || formData.amount == '') {
            return false;
        }
        let body = { ...formData, };
        body.ledger_type = 'Opening Balance';
        body.date = formData.date ? moment(formData.date).format("YYYY-MM-DD") : null;
        const pUrl = API_URL.LEDGER_SAVE;
        axios.post(pUrl, body).then((res) => {
            if (res.data.status === 1) {
                toast.success("Balance Added", {
                    autoClose: 3000,
                    position: "top-center",
                });
                getLedgers();
                resetForm();
            } else {
                resetForm()
            }
        })
    }

    const resetForm = () => {
        setFormData({
            ledger_for: "",
            amount_for: "",
            date: "",
            amount: "",
            client_id: "",
            group_id: ""
        });
    }



    return (
        <>
            <div >
                {!showLedger && < button className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding mb-2 float-right" onClick={(() => setShowLedger(true))}><i className="fa fa-plus"></i> Add Opening Balance</button>}
                {showLedger && <button className="btn btn-danger text-white ctm-border-radius float-right mb-2" onClick={(() => setShowLedger(false))}>Cancel</button>}
            </div >
            {
                showLedger &&
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <label className='form-label'>Opening Balance For</label>
                                <Select
                                    options={Ledger}
                                    value={formData.ledger_for ? (Ledger.find(r => r.value == formData.ledger_for)) : null}
                                    onChange={(e) =>
                                        setFormData({ ...formData, ledger_for: e.value })
                                    }
                                />
                            </div>
                            {
                                formData.ledger_for === "Client" && <div className='col-md-3'>
                                    <label className="form-label">Client Name</label>
                                    <Select
                                        options={clientList}
                                        value={formData.client_id ? (clientList.find(r => r.value == formData.client_id)) : null}
                                        onChange={(e) =>
                                            setFormData({ ...formData, client_id: e.value })
                                        }
                                    />
                                </div>
                            }
                            {
                                formData.ledger_for === "Group" && <div className='col-md-3'>
                                    <label className="form-label">Group Name</label>
                                    <Select
                                        options={groupList}
                                        value={formData.client_id ? (clientList.find(r => r.value == formData.client_id)) : null}
                                        onChange={(e) =>
                                            setFormData({ ...formData, client_id: e.value })
                                        }
                                    />
                                </div>
                            }
                            <div className='col-md-3'>
                                <label className='mb-0 form-label'>Invoice Date</label>
                                <DatePicker
                                    showIcon
                                    className='form-control'
                                    placeholderText='Select Date..'
                                    selected={formData.date}
                                    onChange={(date) => {
                                        setFormData((prevState) => ({ ...prevState, date: date }));
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div className='col-md-3'>
                                <label className="form-label">Amount</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    name="amount"
                                    value={formData.amount}
                                    onChange={(e) => { setFormData({ ...formData, amount: e.target.value }) }}
                                />
                            </div>
                            <div className='col-md-3'>
                                <label className="form-label">CR/DR</label>
                                <Select
                                    options={optionsCr}
                                    value={formData.amount_for ? (optionsCr.find(r => r.value == formData.amount_for)) : null}
                                    onChange={(e) =>
                                        setFormData({ ...formData, amount_for: e.value })
                                    }
                                />
                            </div>
                            <div className='col-md-12 text-right mt-2'>
                                <button
                                    className="btn btn-theme ctm-border-radius text-white mt-2"
                                    onClick={(() => {
                                        resetForm();
                                    })}>
                                    Reset
                                </button>
                                <button
                                    className="btn btn-theme ctm-border-radius text-white ms-2 mt-2"
                                    onClick={(() => {
                                        saveOpeningBalance();
                                    })}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                !showLedger && <div className='card'>
                    <div className='card-body'>
                        {(!selectedRow && !detailMode) && <div className="col-md-12 pr-0 text-right">
                            <label className='form-label'>Closing Balance : {totalAmount} {(totalAmount > 0) ? 'Dr' : 'Cr'}</label>
                        </div>}
                        <div className='mt-3'>
                            {
                                (selectedRow && detailMode) ?
                                    <LedgerView goBack={goBack} item={selectedRow}></LedgerView> :
                                    <LedgerList handleClick={handleViewClick}></LedgerList>
                            }
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default Ledger;
