import React, { useState } from 'react';
import "../../App.css"
import "react-datepicker/dist/react-datepicker.css";
import Accounting from './accounting'
import CC from '../index/cc'
import GstReturn from '../index/gst-return'
import GSTR9 from '../index/gstr9'
import GSTR9C from '../index/gstr9c'
import IEC from '../index/iec'
import ItAudit from '../index/itaudit'
import TDS from '../index/tds'
import TrustAudit from '../index/trustAudit'
import ItIndex from '../index/it-index';
import LLP from '../index/llpAudit';


const FirmIndex = () => {
    const [mode, setMode] = useState(1)
    return (
        <>
            <div className="w-100">
                <div className="card shadow-sm ctm-border-radius">
                    <div className="card-body">
                        <div className="flex-row list-group list-group-horizontal-lg" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className=" active list-group-item" id="v-pills-home-tab" data-toggle="pill" href="reviews.html#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true" onClick={() => { setMode(1) }}>IT Return</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(2) }}>Accounting</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(3) }}>IT Audit</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(7) }}>Trust Audit</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(4) }}>GST Return</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(5) }}>GSTR9</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(6) }}>GSTR9C</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(8) }}>TDS</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(9) }}>CC Renewals</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(10) }}>IEC Renewals</a>
                            <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(11) }}>LLP Audit</a>
                        </div>
                    </div>
                </div>
                {
                    mode === 1 && <ItIndex />
                }
                {
                    mode === 2 && <Accounting />
                }
                {
                    mode === 3 && <ItAudit />
                }
                {
                    mode === 7 && <TrustAudit />
                }
                {
                    mode === 4 && <GstReturn />
                }
                {
                    mode === 5 && <GSTR9 />
                }
                {
                    mode === 6 && <GSTR9C />
                }
                {
                    mode === 8 && <TDS />
                }
                {
                    mode === 9 && <CC />
                }
                {
                    mode === 10 && <IEC />
                }
                {
                    mode === 11 && <LLP />
                }
            </div>
        </>
    )
}

export default FirmIndex
