// const BASE_URL = 'http://127.0.0.1:8000/api/';
const BASE_URL = 'https://api.ppsanja.com/api/';

export const API_URL = {
    LOGIN: `${BASE_URL}login`,
    LOOKUP_LIST: `${BASE_URL}lookup`,
    LOOKUP_SAVE: `${BASE_URL}lookup-store`,

    MINE: `${BASE_URL}me`,
    YEARS_LIST: `${BASE_URL}years`,
    YEARS_CHANGE: `${BASE_URL}change-year`,

    EMP_LIST: `${BASE_URL}employee`,
    EMP_SAVE: `${BASE_URL}employee/store`,
    EMP_UPDATE: `${BASE_URL}employee/update`,
    EMP_DELETE: `${BASE_URL}employee/delete`,
    EMP_SHOW: `${BASE_URL}employee/show`,

    CLIENT_LIST: `${BASE_URL}clients`,
    CLIENT_SAVE: `${BASE_URL}clients/store`,
    CLIENT_UPDATE: `${BASE_URL}clients/update`,
    CLIENT_DELETE: `${BASE_URL}clients/delete`,
    CLIENT_SHOW: `${BASE_URL}clients/show`,

    CLIENT_SERVICES: `${BASE_URL}clients/services`,
    CLIENT_TAGS: `${BASE_URL}clients/tags`,
    CLIENT_ITRETURN: `${BASE_URL}clients/it-return`,

    CLIENT_FIRM_ITRETURN: `${BASE_URL}clients/firm/it-return`,
    CLIENT_ITRETURN_STATUS_UPDATE: `${BASE_URL}clients/it-return/update-status`,

    CLIENT_ACCOUNTING: `${BASE_URL}clients/accounting`,
    CLIENT_ACCOUNTING_STATUS_UPDATE: `${BASE_URL}clients/accounting/update-status`,

    CLIENT_ITAUDIT: `${BASE_URL}clients/it-audit`,
    CLIENT_ITAUDIT_STATUS_UPDATE: `${BASE_URL}clients/it-audit/update-status`,

    CLIENT_GSTRETURN: `${BASE_URL}clients/gst-return`,
    CLIENT_GSTRETURN_STATUS_UPDATE: `${BASE_URL}clients/gst-return/update-status`,

    CLIENT_GSTR9: `${BASE_URL}clients/gstr9`,
    CLIENT_GSTR9_STATUS_UPDATE: `${BASE_URL}clients/gstr9/update-status`,

    CLIENT_GSTR9C: `${BASE_URL}clients/gstr9c`,
    CLIENT_GSTR9C_STATUS_UPDATE: `${BASE_URL}clients/gstr9c/update-status`,

    CLIENT_TRUST_AUDIT: `${BASE_URL}clients/trust-audit`,
    CLIENT_TRUST_AUDIT_STATUS_UPDATE: `${BASE_URL}clients/trust-audit/update-status`,

    CLIENT_TDS: `${BASE_URL}clients/tds`,
    CLIENT_TDS_STATUS_UPDATE: `${BASE_URL}clients/tds/update-status`,

    CLIENT_CC_RENEWALS: `${BASE_URL}clients/cc-renewals`,
    CLIENT_CC_RENEWALS_STATUS_UPDATE: `${BASE_URL}clients/cc-renewals/update-status`,

    CLIENT_IEC_RENEWALS: `${BASE_URL}clients/iec-renewals`,
    CLIENT_IEC_RENEWALS_STATUS_UPDATE: `${BASE_URL}clients/iec-renewals/update-status`,

    DOCUMENT_LIST: `${BASE_URL}document`,
    DOCUMENT_SAVE: `${BASE_URL}document/store`,
    DOCUMENT_UPDATE: `${BASE_URL}document/update`,
    DOCUMENT_DELETE: `${BASE_URL}document/delete`,
    DOCUMENT_SHOW: `${BASE_URL}document/show`,

    NOTES_LIST: `${BASE_URL}clients/notes`,
    NOTES_REMINDER: `${BASE_URL}clients/notes/reminder`,
    NOTES_SAVE: `${BASE_URL}clients/notes/store`,
    NOTES_UPDATE: `${BASE_URL}clients/notes/update`,
    NOTES_DELETE: `${BASE_URL}clients/notes/delete`,
    NOTES_SHOW: `${BASE_URL}clients/notes/show`,

    TAG_LIST: `${BASE_URL}tags`,
    TAG_SAVE: `${BASE_URL}tags/store`,
    TAG_UPDATE: `${BASE_URL}tags/update`,
    TAG_DELETE: `${BASE_URL}tags/delete`,
    TAG_SHOW: `${BASE_URL}tags/show`,

    GROUPS_LIST: `${BASE_URL}groups`,
    GROUPS_SAVE: `${BASE_URL}groups/store`,
    GROUPS_UPDATE: `${BASE_URL}groups/update`,
    GROUPS_DELETE: `${BASE_URL}groups/delete`,
    GROUPS_SHOW: `${BASE_URL}groups/show`,

    CONTACTS_LIST: `${BASE_URL}contacts`,
    CONTACTS_SAVE: `${BASE_URL}contacts/store`,
    CONTACTS_UPDATE: `${BASE_URL}contacts/update`,
    CONTACTS_DELETE: `${BASE_URL}contacts/delete`,
    CONTACTS_SHOW: `${BASE_URL}contacts/show`,

    PAYMENT_LIST: `${BASE_URL}payments`,
    CASH_BANK_LIST: `${BASE_URL}payments/cash-bank`,
    PAYMENT_SAVE: `${BASE_URL}payments/store`,
    PAYMENT_UPDATE: `${BASE_URL}payments/update`,
    PAYMENT_DELETE: `${BASE_URL}payments/delete`,
    PAYMENT_SHOW: `${BASE_URL}payments/show`,

    LEDGER_LIST: `${BASE_URL}ledgers`,
    LEDGER_RECEIPT_LIST: `${BASE_URL}ledgers/list`,
    LEDGER_BY_CLIENT: `${BASE_URL}ledgers/by-client`,
    LEDGER_SAVE: `${BASE_URL}ledgers/save`,
    LEDGER_DELETE: `${BASE_URL}ledgers/delete`,
    LEDGER_SHOW: `${BASE_URL}ledgers/show`,
    LEDGER_TRANSFER: `${BASE_URL}ledgers/transfer`,

    NOT_ROUTING_LIST: `${BASE_URL}non-routing`,
    NOT_ROUTING_SAVE: `${BASE_URL}non-routing/store`,
    NOT_ROUTING_UPDATE: `${BASE_URL}non-routing/update`,
    NOT_ROUTING_DELETE: `${BASE_URL}non-routing/delete`,
    NOT_ROUTING_SHOW: `${BASE_URL}non-routing/show`,

    INVOICES: `${BASE_URL}invoices`,
    PENDING_INVOICE: `${BASE_URL}pending-invoices`,
    SAVE_INVOICE: `${BASE_URL}save-invoices`,
    VIEW_INVOICE: `${BASE_URL}view-invoices`,
    DELETE_INVOICE: `${BASE_URL}delete-invoices`

}