import Dashboard from '../pages/dashboard/dashboard'
import Employee from '../pages/employee/employee';
import Client from '../pages/client/client';
import AddClient from '../pages/client/add-client';
import Index from '../pages/index/index';
import FirmIndex from '../pages/firm-index/firmIndex';
import NonRoutine from '../pages/non-routine/nonRoutine';
import Tags from '../pages/tags/tags';
import Razer from '../pages/ledger/ledger';
import Settings from '../pages/setting/settings';
import Payment from '../pages/pay/pay';
import Invoice from '../pages/pay/invoice';
import Pdf from '../pages/pay/pdf';

const CustomeRoutes = [
    { path: 'dashboard', component: <Dashboard /> },
    { path: 'employee', component: <Employee /> },
    { path: 'client', component: <Client /> },
    { path: 'add-client', component: <AddClient /> },
    { path: 'add-client/:id', component: <AddClient /> },
    { path: 'index', component: <Index /> },
    { path: 'firmIndex', component: <FirmIndex/> },
    { path: 'nonRoutine', component: <NonRoutine/> },
    { path: 'tags', component: <Tags/> },
    { path: 'ledger', component: <Razer/> },
    { path: 'settings', component: <Settings/> },
    { path: 'payment', component: <Payment/> },
    { path: 'add-invoice', component: <Invoice/> },
    { path: 'pdf/:id', component: <Pdf/> },
];

export default CustomeRoutes;