import React, { useEffect, useState } from 'react';
import { API_URL } from '../../const';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { AiOutlineDelete } from 'react-icons/ai';
import moment from 'moment';
import { FaSortNumericUp, FaSortNumericDownAlt } from "react-icons/fa";

const LedgerView = ({ item, goBack }) => {

    const [ledgers, setLedgers] = useState([]);
    const [copyLedgers, setCopyLedgers] = useState([]);
    const [sortByDir, setSortByDir] = useState("");

    const getLedgers = () => {
        axios.post(API_URL.LEDGER_BY_CLIENT, item).then(res => {
            setLedgers(res.data.data);
            setCopyLedgers(res.data.data);
        });
    }

    useEffect(() => {
        let copyData = [...copyLedgers];
        if (sortByDir === 'ASC') {
            copyData.sort((a, b) => {
                return a.balance - b.balance;
            });
            setLedgers(copyData);
        }
        if (sortByDir === 'DESC') {
            copyData.sort((a, b) => {
                return b.balance - a.balance;
            });
            setLedgers(copyData);
        }
        if (sortByDir === '') {
            setLedgers(copyData);
        }
    }, [sortByDir]);

    const deleteLedgerData = (res) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085D6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(API_URL.LEDGER_DELETE, { id: res.id }).then(
                    (res) => {
                        if (res.data.status === 1) {
                            getLedgers();
                        } else {
                            toast.error(res.data.data, {
                                autoClose: 3000,
                                position: "top-center"
                            });
                        }
                    },
                );
            }
        });
    };

    useEffect(() => {
        item && getLedgers();
    }, [item]);

    return (
        <>
            <div className='d-flex align-items-center'>
                <div className='w-50'>
                    <h4 className='title'>{item.name} - {item.contact_number}</h4>
                </div>
                <div className='w-50'>
                    <button className="btn btn-danger text-white ctm-border-radius float-right mb-2" onClick={goBack}>Back</button>
                </div>
            </div>
            <table className="table custom-table table-bordered">
                <thead>
                    <tr>
                        <th style={{ width: '5%' }}>Date</th>
                        <th style={{ width: '5%' }}>Bill/Chq. No.</th>
                        <th style={{ width: '15%' }}>Particular</th>
                        <th style={{ width: '5%',textAlign: 'right' }}>Credit</th>
                        <th style={{ width: '5%',textAlign: 'right' }}>Debit</th>
                        <th style={{ width: '5%'}} onClick={() => {
                            if (sortByDir === '') {
                                setSortByDir('ASC');
                            } else if (sortByDir === 'ASC') {
                                setSortByDir('DESC');
                            } else if (sortByDir === 'DESC') {
                                setSortByDir('');
                            }
                        }}>
                            <div className='d-flex align-items-center justify-content-end'>
                                <div>Amount</div>
                                {sortByDir === 'ASC' && <FaSortNumericUp className='ml-1' />}
                                {sortByDir === 'DESC' && <FaSortNumericDownAlt className='ml-1' />}
                            </div>
                        </th>
                        <th style={{ width: '5%',textAlign: 'center' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ledgers.map((led, k) => {
                            return <tr key={k}>
                                <td>{moment(led.date).format("DD-MM-YYYY")}</td>
                                <td>{(led.invoice) ? led.invoice.invoice_number : ''}</td>
                                <td>{(led.invoice) ? 'Sales' : led.remark}</td>
                                <td style={{textAlign: 'end'}}>{(led.amount_for == 'Cr') ? led.amount : ''}</td>
                                <td style={{textAlign: 'end'}}>{(led.amount_for == 'Dr') ? led.amount : ''}</td>
                                <td style={{textAlign: 'end'}}>{led.balance} {led.balance_mode}</td>
                                {
                                    led.ledger_type === "Opening Balance" ? <td className='text-center'><AiOutlineDelete onClick={() => deleteLedgerData(led)} size={25} /></td> : <td></td>
                                }

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default LedgerView;
