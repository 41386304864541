import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Swal from 'sweetalert2';
import { API_URL } from '../../../const';
import { toast } from 'react-toastify';
import { Oval } from "react-loader-spinner";
import { AiOutlineDelete } from 'react-icons/ai';

const AddPayment = () => {

    const [paymentList, setPaymentList] = useState([]);
    const [cashList, setCashList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [isError, setError] = useState(false);
    const [loading, setLoading] = useState(true);


    const [paymentData, setPaymentData] = useState({
        date: "",
        payment_type: "",
        amount: "",
        remark: "",
        description: "",
    });

    const payment = [
        { value: 'Cash', label: 'Cash' },
        { value: 'Bank', label: 'Bank' },
    ];

    const savePayment = () => {
        if (paymentData.date == '') {
            setError(true);
            return false;
        }
        setError(false);
        let body = { ...paymentData, };
        body.date = paymentData.date ? moment(paymentData.date).format("YYYY-MM-DD") : null;
        const pUrl = API_URL.PAYMENT_SAVE;
        axios.post(pUrl, body).then((res) => {
            if (res.data.status === 1) {
                toast.success("Payment Generated", {
                    autoClose: 3000,
                    position: "top-center",
                });
                resetPaymentDate();
                getPaymentData()
                if (paymentData.payment_type === "Cash") {
                    getCashData()
                } else {
                    getBankData()
                }
            } else {
                resetPaymentDate()
            }
        })
    };

    const resetPaymentDate = () => {
        setPaymentData({
            id: null,
            date: "",
            payment_type: "",
            amount: "",
            remark: "",
            description: "",
        })
    }

    const deletePaymentData = (res) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085D6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(API_URL.PAYMENT_DELETE, { id: res.id }).then(
                    (res) => {
                        if (res.data.status === 1) {
                            getPaymentData();
                        } else {
                            toast.error(res.data.data, {
                                autoClose: 3000,
                                position: "top-center"
                            });
                        }
                    },
                );
            }
        });
    };

    const getPaymentData = () => {
        axios.post(API_URL.PAYMENT_LIST, {}).then(
            (res) => {
                if (res.data.status === 1) {
                    setPaymentList(res.data.data);
                }
                setLoading(false)
            },
            (error) => {
                toast.error("Something went wrong..", {
                    autoClose: 3000,
                    position: "top-center",
                });
                setLoading(false)
            }
        );
    };

    const getBankData = () => {
        axios.post(API_URL.CASH_BANK_LIST, { payment_type: "Bank" }).then(
            (res) => {
                if (res.data.status === 1) {
                    setBankList(res.data.data);
                }
                setLoading(false);
            },
            (error) => {
                toast.error("Something went wrong..", {
                    autoClose: 3000,
                    position: "top-center",
                });
                setLoading(false)
            }
        );
    };

    const getCashData = () => {
        axios.post(API_URL.CASH_BANK_LIST, { payment_type: "Cash" }).then(
            (res) => {
                if (res.data.status === 1) {
                    setCashList(res.data.data);
                }
                setLoading(false)
            },
            (error) => {
                toast.error("Something went wrong..", {
                    autoClose: 3000,
                    position: "top-center",
                });
                setLoading(false)
            }
        );
    };

    useEffect(() => {
        getCashData();
        getBankData();
        getPaymentData();
    }, []);


    return (
        <div className='mt-3'>
            <div className="accountant-receipt-container">
                <div className="accountant-receipt-header" style={{ borderBottom: '2px solid black' }}>
                    <div className='title'>Add Payment</div>
                </div>
                <div>
                    <div className="receipt-details">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <strong className='mr-3' style={{ width: "34%" }}>Payment Date:</strong>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className='form-control w-34'
                                placeholderText='Select Date..'
                                selected={paymentData.date}
                                onChange={(date) => {
                                    setPaymentData((prevState) => ({ ...prevState, date: date }));
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <strong style={{ width: '25%' }} className='mr-3'>Select Payment Method</strong>
                            <Select
                                className='w-25 mt-2'
                                options={payment}
                                value={paymentData.payment_type ? (payment.find(r => r.value == paymentData.payment_type)) : null}
                                onChange={(e) =>
                                    setPaymentData({ ...paymentData, payment_type: e.value })
                                }
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <strong style={{ width: "25%" }} className='mr-3'>Amount Paid</strong>
                            <input
                                type="number    "
                                className='form-control w-25 mt-2'
                                name="credit_days"
                                value={paymentData.amount}
                                onChange={(e) => { setPaymentData({ ...paymentData, amount: e.target.value }) }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <strong style={{ width: "25%" }} className='mr-3'>Description</strong>
                            <input
                                type="text"
                                className='form-control  w-25 mt-2'
                                name="credit_days"
                                value={paymentData.description}
                                onChange={(e) => { setPaymentData({ ...paymentData, description: e.target.value }) }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <strong style={{ width: "25%" }} className='mr-3'>Remark</strong>
                    <input
                        type="text"
                        className='form-control w-25 mt-2'
                        name="Remarks"
                        value={paymentData.remark}
                        onChange={(e) => { setPaymentData({ ...paymentData, remark: e.target.value }) }}
                    />
                </div>
            </div>
            <div className='mt-2'>
                <button className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding" style={{ float: 'right' }} onClick={() => savePayment()}>Submit</button>
                <button className="mr-2 btn btn-danger text-white ctm-border-radius float-right" style={{ float: 'right' }} onClick={() => resetPaymentDate()}>Reset</button>
            </div>
            {
                loading ? <Oval
                    height={60}
                    width={60}
                    color="#7539ff"
                    wrapperStyle={{ justifyContent: "center" }}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#7539ff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
                    :
                    <div className="table-responsive">
                        <table className="table custom-table table-bordered mt-4">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>Payment Date</th>
                                    <th style={{ width: "10%" }}>Payment Mode</th>
                                    <th style={{ width: "40%" }}>Description</th>
                                    <th style={{ width: "20%" }}>Remark</th>
                                    <th style={{ width: "10%" }}>Amount</th>
                                    <th className='text-center' style={{ width: "10%" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentList.map((res, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{res.date}</td>
                                            <td>{res.payment_mode}</td>
                                            <td>{res.description}</td>
                                            <td>{res.remark}</td>
                                            <td>{res.amount}</td>
                                            <td>
                                                <div className="action-section">
                                                    {/* <button type="button" className="btn btn-sm btn-green btn-action-table"><BiEdit size={25} /></button> */}
                                                    <button type="button" className="btn btn-sm btn-red btn-action-table" onClick={() => deletePaymentData(res)}><AiOutlineDelete size={25} /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default AddPayment;
