import React, { useEffect, useState } from 'react';
import { API_URL } from '../../const';
import { NavLink } from 'react-router-dom';
import { Oval } from "react-loader-spinner";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { AiOutlineTag } from 'react-icons/ai';
import { CiViewColumn } from 'react-icons/ci';
import { BiEdit } from 'react-icons/bi';
import { FiFilter } from 'react-icons/fi';
import Select from 'react-select';
import axios from 'axios';
import TagModal from "../../component/modal/tag-modal";

const Client = () => {

    const [loading, setLoading] = useState(true);
    const [clientList, setClientList] = useState([]);
    const [fiYear, setFiYear] = useState(null);
    const [show, setShow] = useState(false);
    const [showColum, setShowColum] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [isFilter, setFilter] = useState(false);
    const [allClientList, setAllClientList] = useState([]);
    
    const handleShow = (row) => {
        setCurrentRow(row);
        setShow(true);
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            padding: '0px'
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            padding: '0px'
        })
    };

    const [selectedColumns, setSelectedColumns] = useState([
        { label: "File No", value: "file_no", search: "" },
        { label: "Client Name", value: "name", search: "" },
        { label: "Firm Type", value: "firm_type", search: "" },
        { label: "Firm Name", value: "firm_name", search: "" },
    ]);
    const [columns, setColumns] = useState([
        { label: "File No", value: "file_no", search: "" },
        { label: "Client Name", value: "name", search: "" },
        { label: "Firm Type", value: "firm_type", search: "" },
        { label: "Firm Name", value: "firm_name", search: "" },
        { label: "Address", value: "address_line_one", search: "" },
        { label: "City", value: "city", search: "" },
        { label: "State", value: "state", search: "" },
        { label: "Pin", value: "pincode", search: "" },
        { label: "Group", value: "group_name", search: "" },
        { label: "Joining Date", value: "joining_date", search: "" },
        { label: "DOB / DOI", value: "dob", search: "" },
        { label: "Client Status", value: "is_active", search: "" },
        { label: "Contact Person-1 Name", value: "person_one_name", search: "" },
        { label: "Contact Person-1 Mobile No", value: "person_one_no", search: "" },
        { label: "Contact Person-1 Email", value: "email_one", search: "" },
        { label: "Contact Person-2 Name", value: "person_two_name", search: "" },
        { label: "Contact Person-2 Mobile No", value: "person_two_no", search: "" },
        { label: "Contact Person-2 Email", value: "email_two", search: "" },
        { label: "Mehtaji Name", value: "mehtaji_name", search: "" },
        { label: "Mehtaji Mo.", value: "mehtaji_no", search: "" },
        { label: "Mehtaji Email", value: "mehtaji_email", search: "" },
        { label: "PAN No", value: "pan_no", search: "" },
        { label: "PAN Password", value: "pan_password", search: "" },
        { label: "TAN No", value: "tan_no", search: "" },
        { label: "Trace Username", value: "trace_username", search: "" },
        { label: "Trace Password", value: "trace_password", search: "" },
        { label: "Income Tax Password", value: "income_tax_password", search: "" },
        { label: "GSTIN No", value: "gstin_no", search: "" },
        { label: "GSTIN Username", value: "gstin_username", search: "" },
        { label: "GSTIN Password", value: "gstin_password", search: "" },
        { label: "E-waybill Username", value: "ewaybill_username", search: "" },
        { label: "E-waybill Password", value: "ewaybill_password", search: "" },
        { label: "IEC No", value: "iec_no", search: "" },
        { label: "IEC Username", value: "iec_username", search: "" },
        { label: "IEC Password", value: "iec_password", search: "" },
        { label: "MSME No", value: "msme_no", search: "" },
        { label: "ROF / LLPIN / CIN No", value: "cin_no", search: "" },
        { label: 'Google Link', value: 'google_link', search: ""}
    ]);

    useEffect(() => {
        let today = new Date();
        let year;
        if (today.getMonth() < 3) {
            year = (today.getFullYear() - 1) + "-" + today.getFullYear()
        }
        else {
            year = (today.getFullYear()) + "-" + (today.getFullYear() + 1)
        }
        setFiYear(year);
        getClient(year);

    }, []);

    const getClient = (year) => {
        axios.post(API_URL.CLIENT_LIST, { year: year }).then(res => {
            if (res.data.status === 1) {
                setClientList(res.data.data);
                setAllClientList(res.data.data)
            }
            setLoading(false);
        });
    }

    const hideShowColumns = (e) => {
        setSelectedColumns(e)
    }
    useEffect(() => {
        let copyData = [...allClientList];
        let selSearch = selectedColumns.filter((d) => d.search);
        if (selSearch.length > 0) {
            let filterArray = [];
            copyData.map((res) => {
                let flagArray = [];
                selSearch.map((filter) => {
                    if (res[filter.value]) {
                        let flag = res[filter.value].toLowerCase().indexOf(filter.search.toLowerCase()) > -1 ? true : false;
                        if (flag) {
                            flagArray.push(flag);
                        }
                    }
                });
                if (flagArray.length === selSearch.length) {
                    filterArray.push(res);
                }
            });
            setClientList(filterArray);
        }
        else {
            setClientList(allClientList);
        }
        setFilter(false);
    }, [selectedColumns, isFilter]);

    return (
        <>
            <div className="w-100">
                <div className="card ctm-border-radius shadow-sm">
                    <div className="card-body align-center">
                        <div className='d-flex justify-content-space-between' style={{ marginBottom: showColum ? '0px' : "5px", alignItems: 'center' }}>
                            <h4 className="title">{clientList.length} Records</h4>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <NavLink to="/add-client" className="text-light">
                                    <button id="modal-2" className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding">
                                        <i className="fa fa-plus"></i> Add New
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                        {
                            showColum && <div className="row mb-3 mt-3">
                                <div className="col">
                                    <Select
                                        isClearable={false}
                                        className="basic-multi-select"
                                        placeholder="Select columns which you want to display"
                                        isMulti={true}
                                        name="columns"
                                        options={columns}
                                        value={selectedColumns}
                                        onChange={(e) => hideShowColumns(e)}
                                        styles={colourStyles}
                                    />
                                </div>
                            </div>
                        }

                        {loading ?
                            <Oval
                                height={60}
                                width={60}
                                color="#7539ff"
                                wrapperStyle={{ justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#7539ff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                            :
                            <div className="employee-office-table">
                                <div className="table-responsive">
                                    <table className="table custom-table table-bordered">
                                        <thead>
                                            <tr>
                                                {
                                                    selectedColumns.map((row, kd) => {
                                                        return <th key={kd}>{row.label}</th>
                                                    })
                                                }
                                                <th>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div>Action</div>
                                                        <div> <CiViewColumn style={{ textAlign: 'end' }} color='white' cursor={'pointer'} size={22} onClick={(() => setShowColum(!showColum))} /></div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {selectedColumns.map((row, kd) => {
                                                    return (
                                                        <td key={kd}>
                                                            <input
                                                                placeholder='Search...'
                                                                type="text"
                                                                style={{ width: '100%', minWidth: '100px', padding: '0px 8px' }}
                                                                className="form-control form-control-sm"
                                                                name="name"
                                                                value={row.search}
                                                                onChange={(e) => {
                                                                    let copyData = [...selectedColumns];
                                                                    copyData[kd].search = e.target.value;
                                                                    setSelectedColumns(copyData);
                                                                }}
                                                            />
                                                        </td>
                                                    )
                                                })}
                                                <td className='text-center'></td>
                                            </tr>
                                            {clientList.map((res, key) => {
                                                return (
                                                    <tr key={key} className={(res.is_active == 0) ? 'text-danger' : ''}>
                                                        {
                                                            selectedColumns.map((row, lk) => {
                                                                return <td key={key + '' + lk}>{res[row.value]}</td>
                                                            })
                                                        }
                                                        <td>
                                                            <div className="action-section">
                                                                <button type="button" className="btn btn-sm btn-green btn-action-table">
                                                                    <NavLink to={'/add-client/' + res.id}><BiEdit size={25} /></NavLink>
                                                                </button>
                                                                <button type="button" className="btn btn-sm btn-red btn-action-table" onClick={() => { handleShow(res) }} >
                                                                    <AiOutlineTag size={25} />
                                                                </button>
                                                                {res.google_link &&
                                                                <button type="button" className="btn btn-sm btn-red btn-action-table">
                                                                   <a href={res.google_link} target='_blank'><HiOutlineClipboardDocumentList size={25} /></a>
                                                                </button>}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                show && <TagModal open={show} row={currentRow} onClose={() => setShow(false)} refresh={() => { getClient(fiYear) }} ></TagModal>
            }
        </>
    )
}
export default Client