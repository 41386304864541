import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../const';
import { MdRefresh } from "react-icons/md";
import AddLedger from '../payment-receipt/add-ledger/add-ledger';
import AddPayment from '../payment-receipt/add-payment/add-payment';
import BankAccount from '../payment-receipt/bank-account/bank-account';
import CashAccount from '../payment-receipt/cash-account/cash-account';
import Contra from '../payment-receipt/contra/contra';


const PaymentReceipt = () => {
    const [bankBalance, setBankBalance] = useState(0);
    const [cashBalance, setCashBalance] = useState(0);

    const [show, setShow] = useState(false);
    const [showCash, setShowCash] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [addPayment, setAddPayment] = useState(false);
    const [contra, setContra] = useState(false);
    const [isRefresh, setRefresh] = useState(false);

    const getBankData = () => {
        axios.post(API_URL.CASH_BANK_LIST, { payment_type: "Bank" }).then((res) => {
            if (res.data.status === 1) {
                if (res.data.data.length > 0) {
                    setBankBalance(res.data.data[res.data.data.length - 1].balance);
                } else {
                    setBankBalance(0);
                }
            }
        });
        setRefresh(false);
    };

    const getCashData = () => {
        axios.post(API_URL.CASH_BANK_LIST, { payment_type: "Cash" }).then((res) => {
            if (res.data.status === 1) {
                if (res.data.data.length > 0) {
                    setCashBalance(res.data.data[res.data.data.length - 1].balance);
                } else {
                    setCashBalance(0);
                }
            }
        });
        setRefresh(false);
    };

    useEffect(() => {
        getBankData();
        getCashData();
    }, []);

    useEffect(() => {
        if (isRefresh) {
            getBankData();
            getCashData();
        }
    }, [isRefresh]);

    return (
        <>
            <div className='card'>
                <div className='card-body d-flex' style={{ justifyContent: 'space-between' }}>
                    <div>
                        <button className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding" onClick={(() => { setAddPayment(!addPayment); setShowCash(false); setShow(false); setShowPayment(false); setContra(false); })}><i className="fa fa-plus"></i> Add Payment</button>
                        <button className="ms-2 btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding" onClick={(() => { setShowPayment(!showPayment); setShowCash(false); setShow(false); setAddPayment(false); setContra(false); })} ><i className="fa fa-plus"></i> Add Receipt</button>
                        <button className="ms-2 btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding" onClick={(() => { setShow(!show); setShowCash(false); setShowPayment(false); setAddPayment(false); setContra(false); })}> Bank Account</button>
                        <button className="ms-2 btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding" onClick={(() => { setShowCash(!showCash); setShow(false); setShowPayment(false); setAddPayment(false); setContra(false); })}> Cash Account</button>
                        <button className="ms-2 btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding" onClick={(() => { setContra(!contra); setShow(false); setShowPayment(false); setAddPayment(false); setShowCash(false); })}> Contra</button>
                    </div>
                    <div className='d-flex'>
                        <div className='amount-total'>
                            <div><strong className='mr-2'>Bank balance: ₹ {bankBalance}</strong></div>
                            <div><strong>Cash balance: ₹ {cashBalance}</strong></div>
                        </div>
                        <div className='ms-2' style={{ cursor: 'pointer' }} onClick={() => { setRefresh(true) }}><MdRefresh size={20} /></div>
                    </div>
                </div>
                <div className='card-body'>
                    {
                        addPayment && <AddPayment />
                    }
                    {
                        show && <BankAccount />
                    }
                    {
                        showCash && <CashAccount />
                    }
                    {
                        showPayment && <AddLedger />
                    }
                    {
                        contra && <Contra />
                    }
                </div>
            </div>
        </>
    )
}

export default PaymentReceipt;
