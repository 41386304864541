import React from 'react'
import { NavLink } from 'react-router-dom';
import { SiAmazonpay } from 'react-icons/si';

const Sidebar = () => {
    return (
        <aside className="sidebar sidebar-user">
            <div className="sidebar-wrapper">
                <div className="card ctm-border-radius shadow-sm  border-none">
                    <div className="card-body p-0">
                        <div className="row no-gutters">
                            <div className="col-12 text-center bdr-btm-1">
                                <NavLink to="/dashboard" className="text-dark p-2 first-slider-btn">
                                    <span className="lnr lnr-home font-23"></span>
                                    <span className="">Dashboard</span>
                                </NavLink>
                            </div>
                            <div className="col-12 shadow-none text-center bdr-btm-1">
                                <NavLink to="/employee" className="text-dark p-2 second-slider-btn">
                                    <span className="lnr lnr-users font-23"></span>
                                    <span>Employees</span>
                                </NavLink>
                            </div>
                            <div className="col-12 shadow-none text-center bdr-btm-1">
                                <NavLink to="/client" className="text-dark p-2">
                                    <span className="lnr lnr-apartment font-23"></span>
                                    <span className="">Client</span>
                                </NavLink>
                            </div>
                            <div className="col-12 shadow-none text-center bdr-btm-1">
                                <NavLink to="/index" className="text-dark p-2">
                                    <span className="lnr lnr-license font-23"></span>
                                    <span className="">IT Index</span>
                                </NavLink>
                            </div>
                            <div className="col-12 shadow-none text-center bdr-btm-1">
                                <NavLink to="/firmindex" className="text-dark p-2">
                                    <span className="lnr lnr-license font-23"></span>
                                    <span className="">Firm Index</span>
                                </NavLink>
                            </div>
                            <div className="col-12 shadow-none text-center bdr-btm-1">
                                <NavLink to="/nonroutine" className="text-dark p-2">
                                    <span className="lnr lnr-briefcase font-23"></span>
                                    <span className="">Non Routine</span>
                                </NavLink>
                            </div>
                            <div className="col-12 shadow-none text-center bdr-btm-1">
                                <NavLink to="/settings" className="text-dark p-2">
                                    <span className="lnr lnr-cog font-23"></span>
                                    <span className="">Setting</span>
                                </NavLink>
                            </div>
                            <div className="col-12 shadow-none text-center">
                                <NavLink to="/payment" className="text-dark p-2 last-slider-btn">
                                    <SiAmazonpay size={25} />
                                    <span className="">Payment</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </aside>
    )
}

export default Sidebar
