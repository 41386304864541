import React, { useState } from 'react'
import ItReturn from '../index/itReturn'

const Index = () => {
    return (
        <div className="w-100">
            <ItReturn />
        </div>
    )
}

export default Index