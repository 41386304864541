import React, { Component } from 'react'
import Header from '../component/header/header'
import Sidebar from '../component/sidebar/sidebar'
import axios from 'axios';

axios.interceptors.request.use(
    (req) => {
        req.headers.Authorization = 'Bearer ' + sessionStorage.getItem('access_token');
        return req;
    },
    (err) => {
        if (err.response.status === 401) {
            // sessionStorage.clear();
            // window.location.href = '/login';
        }
        return Promise.reject(err);
    }
);

// For POST requests
axios.interceptors.response.use(
    (res) => {
        res.headers.Authorization = 'Bearer ' + sessionStorage.getItem('access_token');
        if (res.status === 401) {
            // sessionStorage.clear();
            // window.location.href = '/login';
        }
        return res;
    },
    (err) => {
        if (err.response.status === 401) {
            sessionStorage.clear();
            window.location.href = '/login';
        }
        return Promise.reject(err);
    }
);

const Layout = ({ component: Component }) => (

    <div className="inner-wrapper">
        <Header />
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-1 pr-0 pl-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-11 pr-0">
                        {Component}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Layout;
