import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from '../../const';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { Oval } from "react-loader-spinner";

const Groups = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [groupList, setGroupList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [formData, setFormData] = useState({
    id: '0',
    name: "",
    email: "",
    contact_no: "",
  })

  const onSaveData = () => {
    let url = isEdit ? API_URL.GROUPS_UPDATE : API_URL.GROUPS_SAVE;
    setIsEdit(false)
    let body = { ...formData };
    axios.post(url, body).then((response) => {
      if (response.data.status === 1) {
        getData()
        setFormData({
          name: "",
          email: "",
          contact_no: "",
        })
      } else {
        toast.error('Something went wrong..', { autoClose: 3000, position: "top-center", })
      }
    });
  }

  const getData = () => {
    axios.post(API_URL.GROUPS_LIST, {}).then(
      (res) => {
        if (res.data.status === 1) {
          setGroupList(res.data.data);
        }
        setLoading(false)
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading(false)
      }
    );
  };

  const onEdit = (element) => {
    setIsEdit(true)
    setFormData({
      id: element.id,
      name: element.name,
      email: element.email,
      contact_no: element.contact_no
    });
  }

  useEffect(() => {
    getData();
  }, []);

  const deleteData = (element) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(API_URL.GROUPS_DELETE, { id: element.id }).then(
          (res) => {
            if (res.data.status === 1) {
              getData();
            } else {
              toast.error(res.data.data, {
                autoClose: 3000,
                position: "top-center"
              });
            }
          },
        );
      }
    });
  };

  const isValidForm = () => {
    return formData.name && formData.email && formData.contact_no
  }

  return (

    <div className='row'>
      <div className='col-md-4'>
        <div className='card'>
          <div className='card-body'>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group edit-name">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={(e) => { setFormData({ ...formData, name: e.target.value }) }}
                />
              </div>
              <div className="form-group edit-name">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={formData.email}
                  onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }}
                />
              </div>
              <div className="form-group edit-name">
                <label>Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone_number"
                  maxLength={10}
                  value={formData.contact_no}
                  onChange={(e) => { setFormData({ ...formData, contact_no: e.target.value }) }}
                />
              </div>
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-theme ctm-border-radius text-white float-right"
                  aria-hidden="true"
                  onClick={onSaveData}
                  disabled={!isValidForm()}
                >
                  {
                    isEdit ? 'Update' : 'Create'
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='col-md-8'>
        <div className='card'>
          <div className='card-body'>
            {
              loading ? <Oval
                height={60}
                width={60}
                color="#7539ff"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7539ff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
                :
                <div className="table-responsive">
                  <table className="table custom-table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupList.map((res, key) => {
                        return (
                          <tr key={key}>
                            <td>{res.name}</td>
                            <td>{res.email}</td>
                            <td>{res.contact_no}</td>
                            <td>
                              <div className="action-section">
                                <button type="button" className="btn btn-sm btn-green btn-action-table" onClick={() => { onEdit(res); }}><BiEdit size={25} /></button>
                                <button type="button" className="btn btn-sm btn-red btn-action-table " onClick={() => deleteData(res)}><AiOutlineDelete size={25} /></button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Groups;
