import React, { useState, useEffect } from 'react'
import { RxCross2 } from 'react-icons/rx';
import { API_URL } from '../../const';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Oval } from "react-loader-spinner";

const Tags = () => {
    const [tagList, setTagsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tag, setTag] = useState('');

    const saveData = () => {
        let url = API_URL.TAG_SAVE;
        let body = { id: '0', name: tag };
        axios.post(url, body).then((response) => {
            if (response.data.status === 1) {
                getData();
            } else {
                if (response.data && response.data.data) {
                    toast.error("something Went Wromg.....", { autoClose: 3000, position: "top-center" });
                }
            }
        })
    };

    const getData = () => {
        axios.post(API_URL.TAG_LIST, {}).then(
            (res) => {
                if (res.data.status === 1) {
                    setTagsList(res.data.data);
                }
                setLoading(false)
            },
            (error) => {
                toast.error("Something went wrong..", {
                    autoClose: 3000,
                    position: "top-center",
                });
                setLoading(false)
            }
        );
    };

    const deleteData = (element) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this tags?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085D6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(API_URL.TAG_DELETE, { id: element.id }).then(
                    (res) => {
                        if (res.data.status === 1) {
                            getData();
                        } else {
                            toast.error(res.data.data, {
                                autoClose: 3000,
                                position: "top-center"
                            });
                        }
                    },
                );
            }
        });
    };

    useEffect(() => {
        getData();
    }, []);



    return (
        <>
            <div className="w-100">
                <div className="card shadow-sm ctm-border-radius">
                    <div className="card-body">
                        <div className='d-flex justify-content-space-between' style={{ marginBottom: '5px', alignItems: 'center' }}>
                            <h4 className="title">{tagList.length} Records</h4>
                        </div>
                        <div className='mt-3' style={{ width: '40%' }}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control
                                    placeholder='Enter your tags....'
                                    value={tag}
                                    onChange={(event) => {
                                        setTag(event.target.value);
                                    }}
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') {
                                            saveData();
                                            setTag('')
                                        }
                                    }}
                                />
                            </Form.Group>
                        </div>
                        {
                            loading ? <Oval
                                height={60}
                                width={60}
                                color="#7539ff"
                                wrapperStyle={{ justifyContent: "center" }}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#7539ff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                                :
                                <div className='tags-section'>
                                    {
                                        tagList.map((element, key) => {
                                            return (
                                                <div key={key} className='tags-type'>{element.name}
                                                    {/* <div className='tags-close'>
                                                        <RxCross2 onClick={(() => (deleteData(element)))} size={15} style={{ cursor: 'pointer' }} />
                                                    </div> */}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tags;
