import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../const";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

const NonRoutineTask = () => {
  const [loading, setLoading] = useState(true);
  const [groupList, setGroupList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    id: 0,
    key_name: "",
    key_type: "",
    kay_value: "",
  });

  const onSaveData = () => {
    let url = API_URL.LOOKUP_SAVE;
    setIsEdit(false);
    let body = { ...formData };
    axios.post(url, body).then((response) => {
      if (response.data.status === 1) {
        getData();
        setFormData({
          id: 0,
          key_name: "",
          key_type: "",
          kay_value: "",
        });
      } else {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
      }
    });
  };

  const getData = () => {
    axios.post(API_URL.LOOKUP_LIST).then(
      (res) => {
        if (res.data.status === 1) {
          setGroupList(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading(false);
      }
    );
  };

  const isValidForm = () => {
    return formData.key_name && formData.kay_value;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group edit-name">
                <label>Type</label>
                <select
                  className="form-control"
                  value={formData.key_type}
                  onChange={(e) => {
                    setFormData({ ...formData, key_type: e.target.value });
                  }}
                  name="type"
                  id="type"
                >
                  <option value={""}>Select</option>
                  <option value={"Non Routing"}>Non Routing</option>
                  <option value={"Routing"}>Routing</option>
                </select>
              </div>

              <div className="form-group edit-name">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="key_name"
                  value={formData.key_name}
                  onChange={(e) => {
                    setFormData({ ...formData, key_name: e.target.value });
                  }}
                />
              </div>
              <div className="form-group edit-name">
                <label>Value</label>
                <input
                  type="text"
                  className="form-control"
                  id="kay_value"
                  value={formData.kay_value}
                  onChange={(e) => {
                    setFormData({ ...formData, kay_value: e.target.value });
                  }}
                />
              </div>
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-theme ctm-border-radius text-white float-right"
                  aria-hidden="true"
                  onClick={onSaveData}
                  disabled={!isValidForm()}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="card">
          <div className="card-body">
            {loading ? (
              <Oval
                height={60}
                width={60}
                color="#7539ff"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7539ff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              <div className="table-responsive">
                <table className="table custom-table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupList.map((res, key) => {
                      return (
                        <tr key={key}>
                          <td>{res.key_name}</td>
                          <td>{res.key_type}</td>
                          <td>{res.kay_value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonRoutineTask;
