import React, { useEffect, useState } from 'react';
import "../../App.css"
import { API_URL } from '../../const';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const AddClient = () => {

  let { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [routingData, setRoutingData] = useState([]);
  const [fiYear, setFiYear] = useState(null);
  const [isError, setError] = useState(false);
  const [groupList, setGroupList] = useState([])
  const [clientData, setClientData] = useState({
    id: null,
    is_active: "1",
    file_no: "",
    pan_no: "",
    pan_password: "",
    gstin_no: "",
    gstin_password: "",
    gstin_username: "",
    ewaybill_username: "",
    ewaybill_password: "",
    tan_no: "",
    trace_username: "",
    trace_password: "",
    income_tax_password: "",
    iec_no: "",
    iec_username: "",
    iec_password: "",
    name: "",
    firm_name: "",
    firm_type: "",
    address_line_one: "",
    city: "",
    state: "",
    pincode: "",
    group_id: "",
    joining_date: new Date(),
    dob: new Date(),
    person_one_no: "",
    person_one_name: "",
    person_two_no: "",
    person_two_name: "",
    mehtaji_no: "",
    mehtaji_name: "",
    mehtaji_email: "",
    email_one: "",
    email_two: "",
    msme_no: "",
    cin_no: "",
    cc_month: "",
    google_link: ""
  });

  const options = [
    "Individual",
    "HUF",
    "Firm",
    "LLP",
    "Pvt Ltd",
    "Limited",
    "Trust",
    "Co Operative Society",
    "AOI",
    "BOI"
  ];

  const saveClient = () => {
    if (clientData.file_no == '' ||
      clientData.firm_type == '' ||
      clientData.name == '' ||
      clientData.firm_name == '' ||
      clientData.address_line_one == '' ||
      clientData.city == '' ||
      clientData.state == '' ||
      clientData.pincode == '' ||
      clientData.joining_date == '' ||
      clientData.dob == '' ||
      clientData.person_one_name == '' ||
      clientData.person_one_no == '' ||
      clientData.email_one == '') {
      setError(true);
      return false;
    }
    if (requiredField('Income Tax Return')) {
      if (clientData.pan_no == '' || clientData.pan_password == '') {
        setError(true);
        return false;
      }
    }

    if (requiredField('CC Renewals')) {
      if (clientData.cc_month == '') {
        setError(true);
        return false;
      }
    }

    if (requiredField('GST Return') || requiredField('GSTR 9') || requiredField('GSTR 9C')) {
      if (clientData.gstin_username == '' || clientData.gstin_no == '' || clientData.gstin_password == '') {
        setError(true);
        return false;
      }
    }

    if (requiredField('TDS')) {
      if (clientData.tan_no == '' || clientData.trace_username == '' || clientData.trace_password == '') {
        setError(true);
        return false;
      }
    }

    if (requiredField('IEC Renewals')) {
      if (clientData.iec_no == '' || clientData.iec_username == '' || clientData.iec_password == '') {
        setError(true);
        return false;
      }
    }

    setError(false);
    let payload = { ...clientData };
    let saveUrl = (clientData.id) ? API_URL.CLIENT_UPDATE : API_URL.CLIENT_SAVE;
    payload.joining_date = clientData.joining_date ? moment(clientData.joining_date).format("YYYY-MM-DD") : null;
    axios.post(saveUrl, payload).then(res => {
      if (res.data.status === 1) {
        saveService(res.data.data.id);
        navigate('/client', { replace: true });
      } else {
        toast.error(res.data.data, {
          autoClose: 3000,
          position: "top-center",
        });
      }
    });
  };

  const saveService = (cid) => {
    const routingChecked = routingData.filter(item => item.checked);
    let payload = {
      client_id: cid,
      year: fiYear,
      data: routingChecked
    }
    if (fiYear && routingChecked) {
      axios.post(API_URL.CLIENT_SERVICES, payload).then(res => {
        if (res.data.status === 1) {
          toast.success('Client information successfully saved', {
            autoClose: 3000,
            position: "top-center",
          });
          navigate('/client', { replace: true });
        }
      });
    } else {
      toast.success('Client information successfully saved', {
        autoClose: 3000,
        position: "top-center",
      });
      navigate('/client', { replace: true });
    }
  };

  useEffect(() => {
    let today = new Date();
    let year;
    if (today.getMonth() < 3) {
      year = (today.getFullYear() - 1) + "-" + today.getFullYear()
    }
    else {
      year = (today.getFullYear()) + "-" + (today.getFullYear() + 1)
    }
    setFiYear(year);
    getLookup();
    getGroupsData();
  }, []);

  const getLookup = () => {
    axios.post(API_URL.LOOKUP_LIST, { key_type: ['Routing'] }).then(res => {
      if (res.data.status === 1) {
        const rout = res.data.data.filter(item => item.key_type === 'Routing');
        rout.map((el) => {
          el['checked'] = false;
        })
        setRoutingData(rout)
        if (id) {
          getEdit(id, rout)
        }
      }
      setLoading(false);
    });
  }

  const handleRoutineService = (e, k, fieldName) => {
    let t = [...routingData];
    if (fieldName === 'checked') {
      t[k][fieldName] = e.target.checked;
    } else if (fieldName === 'emp_id') {
      t[k].emp_id = e.target.value;
    }
    else if (fieldName === 'fees') {
      t[k].fees = e.target.value;
    } else {
      t[k][fieldName] = e.target.value;
    }
    setRoutingData(t);
  }

  const getGroupsData = () => {
    axios.post(API_URL.GROUPS_LIST, {}).then(
      (res) => {
        if (res.data.status === 1) {
          setGroupList(res.data.data);
        }
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
      }
    );
  };

  const getEdit = (id, rout) => {
    axios.post(API_URL.CLIENT_SHOW, { id: id }).then(res => {
      if (res.data.status === 1) {
        setClientData({
          id: (res.data.data.id) ? res.data.data.id : "",
          is_active: (res.data.data.is_active) ? res.data.data.is_active : "",
          file_no: (res.data.data.file_no) ? res.data.data.file_no : "",
          name: (res.data.data.name) ? res.data.data.name : "",
          firm_name: (res.data.data.firm_name) ? res.data.data.firm_name : "",
          firm_type: (res.data.data.firm_type) ? res.data.data.firm_type : "",
          address_line_one: (res.data.data.address_line_one) ? res.data.data.address_line_one : "",
          address_line_two: (res.data.data.address_line_two) ? res.data.data.address_line_two : "",
          city: (res.data.data.city) ? res.data.data.city : "",
          state: (res.data.data.state) ? res.data.data.state : "",
          pincode: (res.data.data.pincode) ? res.data.data.pincode : "",
          group_id: (res.data.data.group_id) ? res.data.data.group_id : "",
          sub_group_name: (res.data.data.sub_group_name) ? res.data.data.sub_group_name : "",
          joining_date: (res.data.data.joining_date) ? new Date(res.data.data.joining_date) : "",
          person_one_no: (res.data.data.person_one_no) ? res.data.data.person_one_no : "",
          person_one_name: (res.data.data.person_one_name) ? res.data.data.person_one_name : "",
          person_two_no: (res.data.data.person_two_no) ? res.data.data.person_two_no : "",
          person_two_name: (res.data.data.person_two_name) ? res.data.data.person_two_name : "",
          mehtaji_no: (res.data.data.mehtaji_no) ? res.data.data.mehtaji_no : "",
          mehtaji_name: (res.data.data.mehtaji_name) ? res.data.data.mehtaji_name : "",
          mehtaji_email: (res.data.data.mehtaji_email) ? res.data.data.mehtaji_email : "",
          email_one: (res.data.data.email_one) ? res.data.data.email_one : "",
          email_two: (res.data.data.email_two) ? res.data.data.email_two : "",
          pan_no: (res.data.data.pan_no) ? res.data.data.pan_no : "",
          cc_month: (res.data.data.cc_month) ? res.data.data.cc_month : "",
          pan_password: (res.data.data.pan_password) ? res.data.data.pan_password : "",
          tan_no: (res.data.data.tan_no) ? res.data.data.tan_no : "",
          trace_username: (res.data.data.trace_username) ? res.data.data.trace_username : "",
          trace_password: (res.data.data.trace_password) ? res.data.data.trace_password : "",
          income_tax_password: (res.data.data.income_tax_password) ? res.data.data.income_tax_password : "",
          gstin_no: (res.data.data.gstin_no) ? res.data.data.gstin_no : "",
          gstin_password: (res.data.data.gstin_password) ? res.data.data.gstin_password : "",
          gstin_username: (res.data.data.gstin_username) ? res.data.data.gstin_username : "",
          ewaybill_username: (res.data.data.ewaybill_username) ? res.data.data.ewaybill_username : "",
          ewaybill_password: (res.data.data.ewaybill_password) ? res.data.data.ewaybill_password : "",
          iec_no: (res.data.data.iec_no) ? res.data.data.iec_no : "",
          iec_username: (res.data.data.iec_username) ? res.data.data.iec_username : "",
          iec_password: (res.data.data.iec_password) ? res.data.data.iec_password : "",
          msme_no: (res.data.data.msme_no) ? res.data.data.msme_no : "",
          cin_no: (res.data.data.cin_no) ? res.data.data.cin_no : "",
          dob: (res.data.data.dob) ? new Date(res.data.data.dob) : "",
          google_link: res.data.data.google_link
        });
        handleOpenService(res.data.data, rout);
      }
    });
  }

  const handleOpenService = (res, rout) => {
    if (res.services) {
      let copyData = [...rout];
      res.services.map((item, key) => {
        copyData.map((r) => {
          if (r.id === item.lookup_id) {
            r.checked = true;
            r.is_complete = item.is_complete;
          }
        });
      });
      setRoutingData(copyData);
    }
  }

  const requiredField = (key) => {
    let sel = routingData.filter((d) => d.key_name === key);
    if (sel.length > 0) {
      return (sel[0].checked ? true : false);
    }
    return false;
  }

  const isShowError = (value) => {
    if (isError && (!value || value == '' || value == null || value == undefined)) {
      return true;
    }
    return false;
  }

  return (
    <>
      <div className="w-100">
        <div className="col-md-12">
          <div className="company-doc">
            <div className="card ctm-border-radius shadow-sm">
              <div className="card-header">
                <h4 className="title modal-title float-left mt-1">{clientData.id ? 'Edit' : 'Add'} Customer</h4>
                <NavLink to="/client" className="text-light"><span className="btn btn-danger text-white ctm-border-radius float-right mr-2">Back</span></NavLink>
              </div>
              <div className="card-body align-center">
                <div className="tab-content" id="pills-tabContent">
                  <div className="form-group">
                    <div className="row">
                      <div className="card shadow-sm ctm-border-radius ml-3 mr-3">
                        <div className="card-header" id="modal">
                          <h4 className="cursor-pointer mb-0">
                            <span className="subtitle d-block" data-target="#basic-one" aria-expanded="true">
                              Basic Information
                            </span>
                          </h4>
                        </div>
                        <div className="card-body p-0">
                          <div id="basic-one" className="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details" >
                            <div className="row">
                              <div className="col-md-2 form-group">
                                <label className="form-label">File No</label>
                                <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.file_no) && 'required-field'}`}
                                  name="file_no"
                                  value={clientData.file_no}
                                  onChange={(e) => {
                                    setClientData({ ...clientData, file_no: e.target.value })
                                  }} />
                              </div>
                              <div className="col-md-2 form-group">
                                <label className="form-label">Firm Type</label>
                                <select
                                  className={`form-control select ${isShowError(clientData.firm_type) && 'required-field'}`}
                                  name="firm_type"
                                  value={clientData.firm_type}
                                  style={{ borderColor: isShowError(clientData.firm_type) ? '#dc3545' : null }}
                                  onChange={(e) => {
                                    setClientData({ ...clientData, firm_type: e.target.value })
                                  }}>
                                  <option value="">Select</option>
                                  {options.map((option, index) => {
                                    return (<option key={index}> {option} </option>)
                                  })}
                                </select>
                              </div>
                              <div className="col-md-4 form-group">
                                <label className="form-label">Legal Name</label>
                                <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.name) && 'required-field'}`}
                                  name="name"
                                  value={clientData.name}
                                  onChange={(e) => {
                                    setClientData({ ...clientData, name: e.target.value, firm_name: e.target.value });
                                  }} />
                              </div>
                              <div className="col-md-4 form-group">
                                <label className="form-label">Trade Name</label>
                                <input
                                  type="text"
                                  name="firm_name"
                                  className={`form-control ${isShowError(clientData.firm_name) && 'required-field'}`}
                                  value={clientData.firm_name}
                                  onChange={(e) => {
                                    setClientData({ ...clientData, firm_name: e.target.value })
                                  }} />
                              </div>
                              <div className="col-md-6 form-group">
                                <label className="form-label">Address</label>
                                <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.address_line_one) && 'required-field'}`}
                                  name='address_line_one'
                                  value={clientData.address_line_one}
                                  onChange={(e) => { setClientData({ ...clientData, address_line_one: e.target.value }) }}
                                />
                              </div>
                              <div className="col-md-3 form-group">
                                <label className="form-label">City</label>
                                <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.city) && 'required-field'}`}
                                  value={clientData.city}
                                  style={{ borderColor: isShowError(clientData.city) ? '#dc3545' : null }}
                                  onChange={(e) => { setClientData({ ...clientData, city: e.target.value }) }} />
                              </div>
                              <div className="col-md-3 form-group">
                                <label className="form-label">State</label>
                                <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.state) && 'required-field'}`}
                                  name="state"
                                  value={clientData.state}
                                  onChange={(e) => { setClientData({ ...clientData, state: e.target.value }) }} />
                              </div>
                              <div className="col-md-3 form-group">
                                <label className="form-label">Pin</label>
                                <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.pincode) && 'required-field'}`}
                                  name="pincode"
                                  value={clientData.pincode}
                                  onChange={(e) => { setClientData({ ...clientData, pincode: e.target.value }) }} />
                              </div>
                              <div className="col-md-3 form-group">
                                <label className="form-label">Group Name</label>
                                <select
                                  className="form-control select"
                                  name="group_id "
                                  value={clientData.group_id}
                                  onChange={(e) => {
                                    setClientData({ ...clientData, group_id: e.target.value })
                                  }}>
                                  <option value="">Select</option>
                                  {groupList.map((option, index) => {
                                    return (<option value={option.id} key={index}> {option.name} </option>)
                                  })}
                                </select>
                                {/* <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.group_name) && 'required-field'}`}
                                  name="group_name"
                                  value={clientData.group_name}
                                  onChange={(e) => { setClientData({ ...clientData, group_name: e.target.value }) }} /> */}
                              </div>
                              <div className="col-md-3 form-group">
                                <label className="form-label">Joining Date</label>
                                <DatePicker
                                  className={`form-control ${isShowError(clientData.joining_date) && 'required-field'}`}
                                  name="joining_date"
                                  selected={clientData.joining_date}
                                  onChange={(date) => {
                                    setClientData((prevState) => ({ ...prevState, joining_date: date }));
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </div>
                              <div className="col-md-3 form-group">
                                <label className="form-label">DOB / DOI</label>
                                <DatePicker
                                  className={`form-control ${isShowError(clientData.dob) && 'required-field'}`}
                                  name="joining_date"
                                  selected={clientData.dob}
                                  style={{ borderColor: isShowError(clientData.dob) ? '#dc3545' : null }}
                                  onChange={(date) => {
                                    setClientData((prevState) => ({ ...prevState, dob: date }));
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </div>
                              <div className="col-md-3 form-group">
                                <label className="form-label">Status</label>
                                <select
                                  className="form-control select"
                                  name="is_active"
                                  value={clientData.is_active}
                                  // disabled
                                  onChange={(e) => { setClientData({ ...clientData, is_active: e.target.value }) }} >
                                  <option value="is_active">Select Client Status</option>
                                  <option value="1">Active</option>
                                  <option value="0">Deactived</option>
                                </select>
                              </div>
                              <div className="col-md-9 form-group">
                                <label className="form-label">Google Link</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="google_link"
                                  value={clientData.google_link}
                                  onChange={(e) => { setClientData({ ...clientData, google_link: e.target.value }) }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card shadow-sm ctm-border-radius ml-3 mr-3">
                        <div className="card-header" id="modal">
                          <h4 className="cursor-pointer mb-0">
                            <span className="subtitle d-block" data-target="#basic-one" aria-expanded="true">
                              Contact Information
                            </span>
                          </h4>
                        </div>
                        <div className="card-body p-0">
                          <div id="basic-one" className="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details" >
                            <div className="row">
                              <div className="col-md-4 form-group">
                                <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.person_one_name) && 'required-field'}`}
                                  name="person_one_name"
                                  placeholder='Enter Name 1'
                                  value={clientData.person_one_name}
                                  onChange={(e) => { setClientData({ ...clientData, person_one_name: e.target.value }) }} />
                              </div>
                              <div className="col-md-4 form-group">
                                <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.person_one_no) && 'required-field'}`}
                                  name="person_one_no"
                                  maxLength={10}
                                  placeholder='Enter Mobile Number 1'
                                  value={clientData.person_one_no}
                                  onChange={(e) => { setClientData({ ...clientData, person_one_no: e.target.value }) }} />
                              </div>
                              <div className="col-md-4 form-group">
                                <input
                                  type="text"
                                  className={`form-control ${isShowError(clientData.email_one) && 'required-field'}`}
                                  name="email_one"
                                  placeholder='Enter Email Address 1'
                                  value={clientData.email_one}
                                  onChange={(e) => { setClientData({ ...clientData, email_one: e.target.value }) }} />
                              </div>
                              <div className="col-md-4 form-group">
                                <input className="form-control" type="text" id="person_two_name"
                                  placeholder='Enter Name 2'
                                  value={clientData.person_two_name} onChange={(e) => { setClientData({ ...clientData, person_two_name: e.target.value }) }} />
                              </div>
                              <div className="col-md-4 form-group">
                                <input className="form-control" type="text" id="person_two_no" value={clientData.person_two_no}
                                  placeholder='Enter Mobile Number 2'
                                  onChange={(e) => { setClientData({ ...clientData, person_two_no: e.target.value }) }} />
                              </div>
                              <div className="col-md-4 form-group">
                                <input className="form-control" type="text" id="email_two"
                                  placeholder='Enter Email Address 2'
                                  value={clientData.email_two} onChange={(e) => { setClientData({ ...clientData, email_two: e.target.value }) }} />
                              </div>
                              <div className="col-md-4 form-group">
                                <input className="form-control" type="text" id="mehtaji_name" placeholder='Enter Accountant Name' value={clientData.mehtaji_name} onChange={(e) => { setClientData({ ...clientData, mehtaji_name: e.target.value }) }} />
                              </div>
                              <div className="col-md-4 form-group">
                                <input className="form-control" type="text" id="mehtaji_no"
                                  placeholder='Enter Accountant Mobile Number'
                                  value={clientData.mehtaji_no} onChange={(e) => { setClientData({ ...clientData, mehtaji_no: e.target.value }) }} />
                              </div>
                              <div className="col-md-4 form-group">
                                <input className="form-control" type="text" id="mehtaji_no"
                                  placeholder='Enter Accountant Email Address'
                                  value={clientData.mehtaji_email} onChange={(e) => { setClientData({ ...clientData, mehtaji_email: e.target.value }) }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card shadow-sm ctm-border-radius ml-3 mr-3">
                        <div className="card-header" id="modal">
                          <h4 className="cursor-pointer mb-0">
                            <span className="subtitle d-block" data-target="#tax-details" aria-expanded="true">
                              Services
                            </span>
                          </h4>
                        </div>
                        <div className="card-body p-0">
                          <div id="services-details" className="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details" >
                            <div className="row">
                              {routingData.map((item, k) => {
                                return (
                                  <div className='col-md-3' key={k}>
                                    <div className="form-check form-check-inline">
                                      <input
                                        id={'checkbox' + item.id}
                                        disabled={item.is_complete === 1}
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={(e) => handleRoutineService(e, k, 'checked')}
                                      />
                                      <label className="form-check-label" htmlFor={'checkbox' + item.id}>{item.key_name}</label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card shadow-sm ctm-border-radius ml-3 mr-3">
                        <div className="card-header" id="modal">
                          <h4 className="cursor-pointer mb-0">
                            <span className="subtitle d-block" data-target="#tax-details" aria-expanded="true">
                              Tax Information
                            </span>
                          </h4>
                        </div>
                        <div className="card-body p-0">
                          <div id="tax-details" className="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details" >
                            {requiredField('Income Tax Return') &&
                              <div className="row">
                                <div className="col-md-3 form-group">
                                  <label className="form-label">PAN No</label>
                                  <input
                                    type="text"
                                    name="pan_no"
                                    className={`form-control ${isShowError(clientData.pan_no) && 'required-field'}`}
                                    value={clientData.pan_no}
                                    onChange={(e) => { setClientData({ ...clientData, pan_no: e.target.value }) }} />
                                </div>
                                <div className="col-md-3 form-group">
                                  <label className="form-label">PAN Password</label>
                                  <input
                                    type="text"
                                    name="pan_password"
                                    className={`form-control ${isShowError(clientData.pan_password) && 'required-field'}`}
                                    value={clientData.pan_password}
                                    onChange={(e) => { setClientData({ ...clientData, pan_password: e.target.value }) }} />
                                </div>
                              </div>
                            }

                            {requiredField('CC Renewals') &&
                              <div className="row">
                                <div className="col-md-3 form-group">
                                  <label className="form-label">Month</label>
                                  <input
                                    type="text"
                                    name="cc_month"
                                    className={`form-control ${isShowError(clientData.cc_month) && 'required-field'}`}
                                    value={clientData.cc_month}
                                    onChange={(e) => { setClientData({ ...clientData, cc_month: e.target.value }) }} />
                                </div>
                              </div>
                            }
                            {requiredField('TDS') &&
                              <div className="row">
                                <div className="col-md-3 form-group">
                                  <label className="form-label">TAN No</label>
                                  <input
                                    type="text"
                                    name="tan_no"
                                    className={`form-control ${isShowError(clientData.tan_no) && 'required-field'}`}
                                    value={clientData.tan_no}
                                    onChange={(e) => { setClientData({ ...clientData, tan_no: e.target.value }) }} />
                                </div>
                                <div className="col-md-3 form-group">
                                  <label className="form-label">Trace Username</label>
                                  <input
                                    type="text"
                                    name="trace_username"
                                    className={`form-control ${isShowError(clientData.trace_username) && 'required-field'}`}
                                    value={clientData.trace_username}
                                    onChange={(e) => { setClientData({ ...clientData, trace_username: e.target.value }) }} />
                                </div>
                                <div className="col-md-3 form-group">
                                  <label className="form-label">Trace Password</label>
                                  <input
                                    type="text"
                                    name="trace_password"
                                    className={`form-control ${isShowError(clientData.trace_password) && 'required-field'}`}
                                    value={clientData.trace_password}
                                    onChange={(e) => { setClientData({ ...clientData, trace_password: e.target.value }) }} />
                                </div>
                                <div className="col-md-3 form-group">
                                  <label className="form-label">Income Tax Password</label>
                                  <input
                                    type="text"
                                    name="income_tax_password"
                                    value={clientData.income_tax_password}
                                    className="form-control"
                                    onChange={(e) => { setClientData({ ...clientData, income_tax_password: e.target.value }) }} />
                                </div>
                              </div>}
                            {(requiredField('GST Return') || requiredField('GSTR 9') || requiredField('GSTR 9C')) &&
                              <>
                                <div className="row">
                                  <div className="col-md-3 form-group">
                                    <label className="form-label">GSTIN No</label>
                                    <input
                                      type="text"
                                      name="gstin_no"
                                      className={`form-control ${isShowError(clientData.gstin_no) && 'required-field'}`}
                                      value={clientData.gstin_no}
                                      onChange={(e) => { setClientData({ ...clientData, gstin_no: e.target.value }) }} />
                                  </div>
                                  <div className="col-md-3 form-group">
                                    <label className="form-label">GSTIN Username</label>
                                    <input
                                      type="text"
                                      name="gstin_username"
                                      className={`form-control ${isShowError(clientData.gstin_username) && 'required-field'}`}
                                      value={clientData.gstin_username}
                                      onChange={(e) => { setClientData({ ...clientData, gstin_username: e.target.value }) }} />
                                  </div>
                                  <div className="col-md-3 form-group">
                                    <label className="form-label">GSTIN Password</label>
                                    <input
                                      type="text"
                                      name="gstin_password"
                                      className={`form-control ${isShowError(clientData.gstin_password) && 'required-field'}`}
                                      value={clientData.gstin_password}
                                      onChange={(e) => { setClientData({ ...clientData, gstin_password: e.target.value }) }} />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3 form-group">
                                  </div>
                                  <div className="col-md-3 form-group">
                                    <label className="form-label">E-waybill Username</label>
                                    <input className="form-control" type="text" id="ewaybill_username" value={clientData.ewaybill_username} onChange={(e) => { setClientData({ ...clientData, ewaybill_username: e.target.value }) }} />
                                  </div>
                                  <div className="col-md-3 form-group">
                                    <label className="form-label">E-waybill Password</label>
                                    <input className="form-control" type="text" id="ewaybill_password" value={clientData.ewaybill_password} onChange={(e) => { setClientData({ ...clientData, ewaybill_password: e.target.value }) }} />
                                  </div>
                                </div>
                              </>}
                            {requiredField('IEC Renewals') &&
                              <div className="row">
                                <div className="col-md-3 form-group">
                                  <label className="form-label">IEC No</label>
                                  <input
                                    type="text"
                                    name="iec_no"
                                    className={`form-control ${isShowError(clientData.iec_no) && 'required-field'}`}
                                    value={clientData.iec_no}
                                    onChange={(e) => { setClientData({ ...clientData, iec_no: e.target.value }) }} />
                                </div>
                                <div className="col-md-3 form-group">
                                  <label className="form-label">IEC Username</label>
                                  <input
                                    type="text"
                                    name="iec_username"
                                    className={`form-control ${isShowError(clientData.iec_username) && 'required-field'}`}
                                    value={clientData.iec_username}
                                    onChange={(e) => { setClientData({ ...clientData, iec_username: e.target.value }) }} />
                                </div>
                                <div className="col-md-3 form-group">
                                  <label className="form-label">IEC Password</label>
                                  <input
                                    type="text"
                                    name="iec_password"
                                    className={`form-control ${isShowError(clientData.iec_password) && 'required-field'}`}
                                    value={clientData.iec_password}
                                    onChange={(e) => { setClientData({ ...clientData, iec_password: e.target.value }) }} />
                                </div>
                              </div>}
                            <div className="row">
                              <div className="col-md-3 form-group">
                                <label className="form-label">MSME No</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="msme_no"
                                  value={clientData.msme_no}
                                  onChange={(e) => { setClientData({ ...clientData, msme_no: e.target.value }) }} />
                              </div>
                            </div>
                            {(clientData.firm_type !== '' && clientData.firm_type !== 'Individual' && clientData.firm_type !== 'HUF') &&
                              <div className="row">
                                <div className="col-md-3 form-group">
                                  <label className="form-label">ROF / LLPIN / CIN No</label>
                                  <input className="form-control" type="text" id="cin_no" value={clientData.cin_no} onChange={(e) => { setClientData({ ...clientData, cin_no: e.target.value }) }} />
                                </div>
                              </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <NavLink to="/client" className="text-light"><span className="btn btn-danger text-white ctm-border-radius float-right ml-2">Cancel</span></NavLink>
                  <button type="button" className="btn btn-theme ctm-border-radius text-white float-right " onClick={(() => saveClient())} >Save</button>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  )
}
export default AddClient;