import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../const";
import axios from "axios";
import Logo from "../../image/ca.png";
import QrCode from "../../image/qr.png";
import ReactToPrint from "react-to-print";
import { AiOutlinePrinter } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Pdf = () => {
  let componentRef = useRef();
  const { id } = useParams();
  const [obj, setObj] = useState({});
  const [amountInWord, setAmountInWord] = useState("");
  const getInvoiceDetail = () => {
    axios.post(API_URL.VIEW_INVOICE, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setObj(res.data.data);
      }
      setAmountInWord(inWords(res.data.data.total_amount));
    });
  };
  const navigate = useNavigate();
  useEffect(() => {
    getInvoiceDetail();
  }, [id]);

  const a = [
    "",
    "One ",
    "Two ",
    "Three ",
    "Four ",
    "Five ",
    "Six ",
    "Seven ",
    "Eight ",
    "Nine ",
    "Ten ",
    "Eleven ",
    "Twelve ",
    "Thirteen ",
    "Fourteen ",
    "Fifteen ",
    "Sixteen ",
    "Seventeen ",
    "Eighteen ",
    "Nineteen ",
  ];
  const b = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  const inWords = (num) => {
    num = parseInt(num);
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != "0" && n[1] != "00"
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
        : "";
    str +=
      n[2] != "0" && n[2] != "00"
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] != "0" && n[3] != "00"
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
        : "";
    str +=
      n[4] != "0" && n[4] != "00"
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
        : "";
    str +=
      n[5] != "0" && n[5] != "00" && n[5] != "000"
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]])
        : "";
    str += " only";
    return str;
  };
  const handleClick = () => {
    navigate("/payment");
  };
  return (
    <>
      {componentRef && (
        <ReactToPrint
          content={() => componentRef}
          trigger={() => {
            return (
              <button
                style={{ borderBottom: "0px" }}
                className="btn btn-danger text-white ctm-border-radius float-right mr-3"
              >
                <AiOutlinePrinter size={25} />
              </button>
            );
          }}
        />
      )}
      <button
        style={{ borderBottom: "0px" }}
        className="btn btn-danger text-white ctm-border-radius float-right mr-3"
        size={25}
        onClick={handleClick}
      >
        Back
      </button>
      <div
        style={{ padding: "20px", marginTop: "50px", maxWidth: "100%" }}
        ref={(el) => (componentRef = el)}
      >
        <div
          style={{
            margin: "0 auto",
            border: "1px solid #ccc",
            padding: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            background: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <img className="logo" src={Logo} width={300} height={75} />
          </div>
          <div style={{ borderTop: "1px solid black" }}></div>
          <div
            style={{ fontSize: "25px", fontWeight: "600", textAlign: "center" }}
          >
            Invoice
          </div>
          <div
            style={{ fontSize: "20px", fontWeight: "600", textAlign: "start" }}
          >
            Bill To
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <strong>{obj?.client?.firm_name || obj?.group?.name}</strong>
              <strong>{obj?.client?.email_one || obj?.group?.email}</strong>
              {obj?.client?.address_line_one && (
                <strong style={{ textTransform: "capitalize" }}>
                  {obj?.client?.address_line_one}
                </strong>
              )}
              <strong>
                {obj?.client?.person_one_no || obj?.group?.contact_no}
              </strong>
            </div>
            <div>
              <p>
                <strong className="mr-2">Invoice No:</strong>
                <strong>{obj?.invoice_number}</strong>
              </p>
              <p>
                <strong className="mr-2">Invoice Date:</strong>
                <strong>{obj?.invoice_date}</strong>
              </p>
            </div>
          </div>
          <div
            className="print-table-section"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <table
              style={{
                marginTop: "20px",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                <tr>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px",
                      textAlign: "center",
                      width: "2%",
                    }}
                  >
                    Sr.no
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px",
                      textAlign: "center",
                      width: "30%",
                    }}
                  >
                    Particular
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px",
                      textAlign: "center",
                      width: "10%",
                    }}
                  >
                    Year
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px",
                      textAlign: "center",
                      width: "10%",
                    }}
                  >
                    Services
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px",
                      textAlign: "end",
                      width: "10%",
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {obj.items &&
                  obj.items.map((element, key) => {
                    return (
                      <tr key={key}>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          <strong>{key + 1}</strong>
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          <strong>{element.client.firm_name}</strong>
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          <strong>{element.year}</strong>
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          <strong>{element.lookup.key_name}</strong>
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "5px",
                            textAlign: "end",
                          }}
                        >
                          <strong>
                            {element.amount.toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "INR",
                            })}
                          </strong>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <table style={{ width: "100%", marginTop: "20px" }}>
            <tbody>
              <tr style={{ display: "flex", justifyContent: "space-between" }}>
                <td>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <strong>PAN No : DYWPS4157M</strong>
                    <strong>Bank Details : </strong>
                    <strong>Bank : ICICI Bank</strong>
                    <strong>IFSC Code : ICIC0001165</strong>
                    <strong>Account No : 116505000581</strong>
                    <strong>UPI Id : sanjaco@icici</strong>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <strong>Scan To Pay</strong>
                    <img
                      className="logo"
                      src={QrCode}
                      width={150}
                      height={150}
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                      textAlign: "end",
                    }}
                  >
                    <div>
                      <strong>
                        Total:{" "}
                        {obj?.total_amount?.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "INR",
                        })}
                      </strong>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      In Words : {amountInWord}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="page-footer" style={{ width: "100%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            ></div>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              In case of any queries, please contact Sanja Ca at +91 70165 37879
              or drop us an email at casanjaco@gmail.com
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pdf;
