import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import { API_URL } from '../../const';
import Select from 'react-select'
import axios from 'axios';

const TagModal = ({ open, row, onClose, refresh = () => { } }) => {

    const [show, setShow] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagList, setTagsList] = useState([]);
    const [updateValue, setUpdateValue] = useState(false);

    const getData = () => {
        axios.post(API_URL.TAG_LIST, {}).then((res) => {
            setTagsList(res.data.data);
            let filterTagList = res.data.data.map((element) => {
                return {
                    label: element.name,
                    value: element.id,
                }
            });
            setTagsList(filterTagList);
            setUpdateValue(true);
        });
    };

    const setTagValue = () => {
        if (row.tags && row.tags.length > 0) {
            let selectedTags = [];
            row.tags.map((element) => {
                let sel = tagList.find((d) => d.value === element.tag_id);
                if (sel) {
                    selectedTags.push(sel);
                }
            });
            setTags(selectedTags);
            setUpdateValue(false);
        }
    }

    useEffect(() => {
        updateValue && setTagValue();
    }, [row, updateValue])

    const saveData = (val) => {
        axios.post(API_URL.CLIENT_TAGS, { client_id: row.id, tags: val }).then((res) => {
            refresh();
        });
    };

    useEffect(() => {
        open ? setShow(true) : setShow(false);
    }, [open]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <Modal size='lg' show={show} onHide={() => { onClose(); setShow(false) }} animation={true} centered>
            <Modal.Header className='modal-header-badges'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span className="badge badge-secondary">{row.file_no}</span>
                    <div className='title ms-2'>{row.name} Tags</div>
                </div>
                <RxCross2 size={25} onClick={() => { onClose(); setShow(false) }} style={{ cursor: 'pointer' }} />
            </Modal.Header>
            <Modal.Body style={{ height: '100px' }}>
                <Select
                    isClearable={false}
                    isMulti={true}
                    value={tags}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: 14,
                            fontWeight: 600
                        }),
                    }}
                    options={tagList}
                    onChange={(e) => {
                        setTags(e);
                        saveData(e);
                    }}
                />
            </Modal.Body>
        </Modal>
    )
}

export default TagModal;
