import React, { useState } from 'react';
import PendingInvoice from '../pay/pending-invoice';
import Complete from '../pay/complate-invoice';
import Invoice from '../pay/invoice';
import Ledger from '../ledger/ledger';
import Receipt from '../pay/payment-receipt';

const Settings = () => {
  const [mode, setMode] = useState(1);
  return (
    <>
      <div className="w-100">
        <div className="card shadow-sm ctm-border-radius">
          <div className="card-body">
            <div className="flex-row list-group list-group-horizontal-lg" style={{ display: 'flex', alignItems: 'center' }} id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a className=" active list-group-item" id="v-pills-home-tab" data-toggle="pill" href="reviews.html#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true" onClick={() => { setMode(1) }}>Pending</a>
              <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(3) }}>Invoice</a>
              <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(4) }}>Ledger</a>
              <a className="list-group-item" id="v-pills-profile-tab" data-toggle="pill" href="reviews.html#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false" onClick={() => { setMode(5) }}>Payment Receipt/Cash Bank</a>
            </div>
          </div>
        </div>
        {
          mode === 1 && <PendingInvoice />
        }
        {
          mode === 3 && <Invoice />
        }
        {
          mode === 4 && <Ledger />
        }
        {
          mode === 5 && <Receipt />
        }
      </div>
    </>
  )
}

export default Settings;
